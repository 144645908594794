import { useState, useEffect } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import { useAppState } from "./useAppState";
import mapStyles from "../assets/data/MapStyles.json";

export const useMap = () => {
  const { departmentName, currentLatLng, setCurrentLatLng,isServiceDetailOpened } = useAppState();
  const [center, setCenter] = useState({ lat: -12.0463851, lng: -77.0427655 });
  const [map, setMap] = useState(null);
  const [zoomValue, setZoomValue] = useState(14);
  const [mapRendered, setMapRendered] = useState(false);
  const [mapContainerStyle, setMapContainerStyle] = useState({
    width: "100%",
    height: '100%',

  });
  const { latitude, longitude } = currentLatLng;

  const mapOptions = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
  };

  const locationOptions = {
    enableHighAccuracy: true,
  };

  const handleSizeMap = () => {
    const currentWidth = window.innerWidth;
    if (currentWidth > 1000){
      setMapContainerStyle({
        width: "100%",
        height: 'calc( 100vh - 160px )',
      })
    }else{
      if(isServiceDetailOpened){
        setMapContainerStyle({
          width: "100%",
          height: 'calc( 100vh - 120px )',
        })
      }else{
        setMapContainerStyle({
          width: "100%",
          height: 'calc( 100vh - 80px )',
        })
      }
    }
      
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDn2GMLm9UkM-bHJqmAxUUNfMCRgys37No",
  });

  const onLoad = (mapReturned) => {
      const bounds = new window.google.maps.LatLngBounds(center);
      mapReturned.fitBounds(bounds);
      setMap(mapReturned);
      mapReturned.setZoom(zoomValue);
      setMapRendered(true);
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        getCoordinates,
        handlelocationError,
        locationOptions
      );
      return;
    }
    console.error("Tu navegador no tiene opción de Geolocalización");
  };

  const getCoordinates = ({ coords }) => {
    setCurrentLatLng({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
    setCenter({ lat: coords.latitude, lng: coords.longitude });
  };

  const handlelocationError = (error) => {
    let errorText = "Error al intentar obtener geolocalización: ";
    switch (error.code) {
      case 1:
        errorText += "Permiso denegado";
        break;
      case 2:
        errorText += "Posición no disponible";
        break;
      case 3:
        errorText += "Tiempo de espera excedido";
        break;
      default:
        errorText += "Error desconocido";
        break;
    }
    console.error(errorText);
  };

  const updateMapView = () => {
    // if ( initialized.current ) {
    setTimeout(() => {
      getGeocode({ address: `Peru ${departmentName}` })
          .then((results) => {
          getLatLng(results[0]);
        })
        .then(() => {
          getLocation();
          // setZoomValue(departmentName === "LIMA" ? 12 : 13.5);
          setZoomValue(departmentName === "LIMA" ? 14 : 15);
        })
        .catch((error) => {
          console.error("Error: ", error);
        });
    }, 500);
    // } else initialized.current = true;
  };

  useEffect(() => {
    departmentName !== "" && updateMapView();
  }, [departmentName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (map !== null) {
      setTimeout(() => {
        map.setZoom(zoomValue);
        setZoomValue(zoomValue);
      }, 300);
    }
  }, [map]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    handleSizeMap,
    getLocation,
    isLoaded,
    onLoad,
    mapContainerStyle,
    mapOptions,
    zoomValue,
    center,
    latitude,
    longitude,
    mapRendered,
    map,
    setCenter
  };
};
