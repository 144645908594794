import { useContext, useEffect } from "react";
import { AppContext } from "../context/AppContext";

export const useAppState = () => {
    const {
        appState,
        handlePanelSideBarCollapsed,
        handleServiceDetailVisibility,
        setCategoryId,
        setCollapsed,
        setCompany,
        setConfirmModalOpen,
        setCurrentLatLng,
        setDepartmentClassName,
        setDepartmentId,
        setDepartmentName,
        setDepartments,
        setDistricts,
        setDetailServiceExtended,
        setDetailServiceOpened,
        setDistrictId,
        setDistrictName,
        setLoggedUser,
        setModifiedUser,
        setModalLoading,
        setModalRegisterCodeOpen,
        setModalRecoverPasswordOpen,
        setModalOkOpen,
        setMode,
        setNewService,
        setNewServiceCopy,
        setPanelSideBarCollapsed,
        setPublishServiceModalOpen,
        setPublishServiceStep,
        setPublishServiceType,
        setSearchServiceText,
        setServiceImages,
        setServices,
        setUserServices,
        setServiceSelected,
        setShareOpened,
        setSubcategoryId,
        setSubCategories,
        signIn,
        signOut,
        setDistrictsAll,
        setModalRegisterComent,
        setModalOkResena,
        setResena,
        setUsersAll,
        setModalInicioSesion,
        setImageReseAll,
        setNewServiceResena,
        setServiceSelectedAux,
        setModalListResena,
        setServiceList,
        setIsImageAvaible,
        setServicesAll,
        setImagenesAudio,
        setAudioConfig,
        setIsActivoService,
        setImagenesPreloader,
        setActiveLogged,
        setActiveGeolocalizacion,
        setActiveServicesAll
    } = useContext(AppContext);

    const {
        categoryId,
        collapsed,
        company,
        confirmModalOpen,
        currentLatLng,
        departmentClassName,
        departmentId,
        departmentName,
        departments,
        districts,
        districtId,
        districtName,
        isPanelSideBarCollapsed,
        isServiceDetailExtended,
        isServiceDetailOpened,
        isServiceShareOpened,
        logged,
        loggedUser,
        modifiedUser,
        modalLoading,
        modalRegisterCodeOpen,
        modalRecoverPasswordOpen,
        modalOkOpen,
        mode,
        newService,
        newServiceCopy,
        publishServiceModalOpen,
        publishServiceStep,
        publishServiceType,
        searchText,
        serviceImages,
        services,
        userServices,
        serviceSelected,
        subcategoryId,
        subCategories,
        districtsAll,
        modalRegisterComent,
        modalOkResena,
        resena,
        usersAll,
        modalInicioSesion,
        imageReseAll,
        newServiceResena,
        serviceSelectedAux,
        modalListResena,
        serviceList,
        isImageAvaible,
        servicesAll,
        imagenesAudio,
        audioConfig,
        isActivoService,
        imagenesPreloader,
        activeLogged,
        activeGeolocalizacion,
        activeServicesAll
    } = appState;

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            setLoggedUser(user);
            //setModifiedUser(user);
            signIn();
        }
    }, [logged]);

    return {
        //values
        categoryId,
        collapsed,
        company,
        confirmModalOpen,
        currentLatLng,
        departmentClassName,
        departmentId,
        departmentName,
        departments,
        districts,
        districtId,
        districtName,
        isPanelSideBarCollapsed,
        isServiceDetailExtended,
        isServiceDetailOpened,
        isServiceShareOpened,
        logged,
        loggedUser,
        modifiedUser,
        modalLoading,
        modalRegisterCodeOpen,
        modalRecoverPasswordOpen,
        modalOkOpen,
        mode,
        newService,
        newServiceCopy,
        publishServiceModalOpen,
        publishServiceStep,
        publishServiceType,
        searchText,
        serviceImages,
        services,
        userServices,
        serviceSelected,
        subcategoryId,
        subCategories,
        //methods
        handlePanelSideBarCollapsed,
        handleServiceDetailVisibility,
        setCategoryId,
        setCollapsed,
        setCompany,
        setConfirmModalOpen,
        setCurrentLatLng,
        setDepartmentClassName,
        setDepartmentId,
        setDepartmentName,
        setDepartments,
        setDistricts,
        setDetailServiceExtended,
        setDetailServiceOpened,
        setDistrictId,
        setDistrictName,
        setLoggedUser,
        setModifiedUser,
        setModalLoading,
        setModalRegisterCodeOpen,
        setModalRecoverPasswordOpen,
        setModalOkOpen,
        setMode,
        setNewService,
        setNewServiceCopy,
        setPanelSideBarCollapsed,
        setPublishServiceModalOpen,
        setPublishServiceStep,
        setPublishServiceType,
        setSearchServiceText,
        setServiceImages,
        setServices,
        setUserServices,
        setServiceSelected,
        setShareOpened,
        setSubcategoryId,
        setSubCategories,
        signIn,
        signOut,
        districtsAll,
        setDistrictsAll,
        setModalRegisterComent,
        modalRegisterComent,
        setModalOkResena,
        modalOkResena,
        setResena,
        resena,
        setUsersAll,
        usersAll,
        setModalInicioSesion,
        modalInicioSesion,
        setImageReseAll,
        imageReseAll,
        newServiceResena,
        setNewServiceResena,
        setServiceSelectedAux,
        serviceSelectedAux,
        modalListResena,
        setModalListResena,
        serviceList,
        setServiceList,
        isImageAvaible,
        setIsImageAvaible,
        servicesAll,
        setServicesAll,
        imagenesAudio,
        setImagenesAudio,
        audioConfig,
        setAudioConfig,
        setIsActivoService,
        isActivoService,
        setImagenesPreloader,
        imagenesPreloader,
        activeLogged,
        setActiveLogged,
        activeGeolocalizacion,
        setActiveGeolocalizacion,
        activeServicesAll,
        setActiveServicesAll
    };
};
