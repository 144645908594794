import AtencionCliente from "../../../../../assets/images/panel/capacitacion/AtencionCliente.jpg";
import AtencionClienteAudio from "../../../../../assets/images/panel/capacitacion/AtencionClienteAudio.jpg";
import GestionRestaurante from "../../../../../assets/images/panel/capacitacion/GestionRestaurante.jpg";
import MarketingEmprendedores from "../../../../../assets/images/panel/capacitacion/MarketingEmprendedores.jpg";
import PrimerosAuxilios from "../../../../../assets/images/panel/capacitacion/PrimerosAuxilios.jpg";
import ManipulacionAlimento from "../../../../../assets/images/panel/capacitacion/ManipulacionAlimento.jpg";

import avatar01 from "../../../../../assets/images/panel/capacitacion/avatar01.png";
import avatar02 from "../../../../../assets/images/panel/capacitacion/avatar02.png";
import avatar03 from "../../../../../assets/images/panel/capacitacion/avatar03.png";

export const imageMap = {
  1: AtencionCliente,
  2: GestionRestaurante,
  3: MarketingEmprendedores
};

export const imageMapAvatar = {
  3: avatar01,
  2: avatar02,
  1: avatar03
};

export const imageChapter = {
  1: AtencionCliente,
  2: PrimerosAuxilios,
  3: ManipulacionAlimento
};

