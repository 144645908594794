import React from 'react';
import { 
    TextField,
    MenuItem,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox,
    FormGroup,
    Button,
    Autocomplete
} from '@mui/material';

import '../../assets/sass/libs/ComponentPage.scss';

const typeOfServices = [
    {
      value: 'PRO',
      label: 'Producto',
    },
    {
      value: 'SER',
      label: 'Servicios',
    },
    {
      value: 'TRA',
      label: 'Trabajos',
    },
];

const classesName = [
    'Primary',
    'Secondary',
    'Success',
    'Danger',
    'Warning',
    'Info',
    'Light',
    'Dark',
    'White',
    'Link',
]

const colors = [
    'blue',
    'cyan',
    'green',
    'red',
    'yellow',
    'purple',
    'gray',
    'black',
    'white',
]

const departamentos = [
    { label: 'Lima', value: 1392 },
    { label: 'Arequipa', value: 1393 },
    { label: 'Junin', value: 1394 },
    { label: 'Trujillo', value: 1395 },
    { label: 'Cajarmarca', value: 1396 },
];


export const ComponentsPage = () => {

    const [ typeService, setTypeService ] = React.useState( 'PRO' );
    const [ value, setValue ] = React.useState('');
    const [ file, setFile ] = React.useState();

    const handleChange = ( event ) => {
        setTypeService( event.target.value );
    };

    const handleChangeInput = ( event) => {
        setValue(event.target.value);
    };

    const copy = ( text ) => {
        navigator.clipboard.writeText( text );
    }

    return (

        <div className = 'container'>

            <div className = 'mpi-bg-blue c-title'>
                <h1> Mappi Components  </h1>
            </div>

            <h3 className = 'component-subtitle'>Buttons</h3>

            <div className = 'items-container'>
                {
                    classesName.filter( x => x !== 'White').map( c => {
                        let name = c.toLowerCase();
                        return (
                            <div key = { c } className = 'item-content'>
                                <button 
                                    className = { `mpi-btn-${ name }` }
                                    onClick = { () => copy(`<button className='mpi-btn-${ name }'>${ c }</button>`) }
                                >{ c }</button>
                                <p
                                    onClick = { () => copy( `mpi-btn-${ name }`) }>
                                    { `mpi-btn-${ name }` }
                                </p>
                            </div>
                        )
                    })
                }
            </div>

            <hr />

            <h3 className = 'component-subtitle'>Outlines buttons</h3>
            
            <div className = 'items-container'>
                {
                    classesName.filter( x => x !== 'White' && x !== 'Link' ).map( c => {
                        let name = c.toLowerCase();
                        return (
                            <div className = 'item-content' key = { c }>
                                <button 
                                    className = { `mpi-btn-outline-${ name}` }
                                    onClick = { () => copy(`<button className='mpi-btn-outline-${ name }'>${ c }</button>`) }
                                >{ c }</button>
                                <p
                                    onClick = { () => copy( `mpi-btn-outline-${ name }`) }
                                >{ `mpi-btn-outline-${ name }` }</p>
                            </div>
                        )
                    })
                }
            </div>

            <hr />

            <h3 className = 'component-subtitle'>Gradients buttons</h3>

            <div className = 'items-container'>
                {
                    classesName.filter( x => x !== 'White' && x !== 'Link' ).map( c => {
                        let name = c.toLowerCase();
                        return (
                            <div className = 'item-content'>
                                <button 
                                    className = { `mpi-btn-gradient-${ name }` }
                                    onClick = { () => copy(`<button className='mpi-btn-gradient-${ name }'>${ c }</button>`) }
                                >{ c }</button>
                                <p
                                    onClick = { () => copy( `mpi-btn-gradient-${ name }`) }
                                >mpi-btn-gradient-primary</p>
                            </div>
                        )
                    })
                }
            </div>

            <hr />

            <h3 className = 'component-subtitle'>Upload Buttons</h3>

            <div className = 'items-container'>
                <div className = 'item-content' style = {{ width: 350 }}>
                    <Button variant = 'contained' component = 'label'>
                        Subir
                        <input hidden type = 'file' value = { file } onChange = { ({ target: { value } }) => setFile( value ) }/>
                    </Button>
                    <p
                        onClick = { () => copy(`
                            <Button variant = 'contained' component = 'label'>
                                Subir
                                <input hidden type = 'file' value = { file } onChange = { ({ target: { value } }) => setFile( value ) }/>
                            </Button>
                        `) }
                    >Upload files</p>
                </div>
            </div>

            <hr />

            <h3 className = 'component-subtitle'>Background colors</h3>

            <div className = 'items-container'>
                {
                    colors.map( c => (
                        <div className = 'item-content'>
                            <div className = 'square'>
                                <span className = { `mpi-bg-${ c }` }></span>
                            </div>
                            <p
                                onClick = { () => copy(`mpi-bg-${ c }`) }
                            >{ `mpi-bg-${ c }` }</p>
                        </div>
                    ))
                }
            </div>

            <hr />

            <h3 className = 'component-subtitle'>Inputs</h3>

            <div className = 'items-container'>
                <div className = 'item-content'>
                    <TextField 
                        label = 'Usuario'
                        variant = 'outlined'
                    />
                    <p onClick = { () => copy(
                        `
                        <TextField 
                            label = 'Usuario'
                            variant = 'outlined'
                        />
                        `
                    ) }>Normal input</p>
                </div>
                <div className = 'item-content'>
                    <TextField 
                        label = 'Correo'
                        variant = 'outlined'
                        required
                    />
                    <p onClick = { () => copy(
                        `
                        <TextField 
                            label = 'Correo'
                            variant = 'outlined'
                            required
                        />
                        `
                    ) }>Required input</p>
                </div>
                <div className = 'item-content'>
                    <TextField 
                        label = 'Password'
                        type = 'password'
                        variant = 'outlined'
                    />
                    <p onClick = { () => copy(
                        `
                        <TextField 
                            label = 'Password'
                            type = 'password'
                            variant = 'outlined'
                        />
                        `
                    ) }>Password input</p>
                </div>
                <div className = 'item-content'>
                    <TextField 
                        label = 'Usuario'
                        value = 'mappi123'
                        InputProps = {{
                            readOnly: true,
                        }}
                        variant = 'outlined'
                    />
                    <p onClick = { () => copy(
                        `
                        <TextField 
                            label = 'Usuario'
                            value = 'mappi123'
                            InputProps = {{
                                readOnly: true,
                            }}
                            variant = 'outlined'
                        />
                        `
                    ) }>Read Only</p>
                </div>
                <div className = 'item-content'>
                    <TextField 
                        label = 'DNI'
                        type = 'number'
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onInput = {(e) =>{
                            e.target.value = (e.target.value).toString().slice(0,8)
                        }}
                        variant = 'outlined'
                    />
                    <p onClick = { () => copy(
                        `
                        <TextField 
                            label = 'DNI'
                            type = 'number'
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onInput = {(e) =>{
                                e.target.value = (e.target.value).toString().slice(0,8)
                            }}
                            variant = 'outlined'
                        />
                        `
                    ) }>Number & limited input</p>
                </div>
                <div className = 'item-content'>
                    <TextField 
                        disabled
                        label = 'Correo'
                        value = 'user@mappi.pe'
                        variant = 'outlined'
                    />
                    <p onClick = { () => copy(
                        `
                        <TextField 
                            disabled
                            label = 'Correo'
                            value = 'user@mappi.pe'
                            variant = 'outlined'
                        />
                        `
                    ) }>Disabled input</p>
                </div>
                <div className = 'item-content'>
                    <TextField
                        error
                        label = 'Error'
                        variant = 'outlined'
                        helperText = 'Ha ocurrido un error.'
                    />
                    <p onClick = { () => copy(
                        `
                        <TextField
                            error
                            label = 'Error'
                            variant = 'outlined'
                            helperText = 'Ha ocurrido un error.'
                        />
                        `
                    ) }>Error input</p>
                </div>
            </div>

            <hr />

            <h3 className = 'component-subtitle'>Select</h3>
            
            <div className = 'items-container'>
                <div className = 'item-content' style = {{ width: 230 }}>
                    <TextField
                        select
                        label = 'Seleccione'
                        value = { typeService }
                        onChange = { handleChange }
                    >
                    {
                        typeOfServices.map( ( option ) => (
                            <MenuItem key = { option.value } value = { option.value }>
                                { option.label }
                            </MenuItem>
                        ))
                    }
                    </TextField>
                    <p
                        onClick = { () => copy(
                            `
                            <TextField
                                select
                                label = 'Seleccione'
                                value = { typeService }
                                onChange = { handleChange }
                            >
                            {
                                typeOfServices.map( ( option ) => (
                                    <MenuItem key = { option.value } value = { option.value }>
                                        { option.label }
                                    </MenuItem>
                                ))
                            }
                            </TextField>
                            `
                        )}
                    >Normal select</p>
                </div>
                <div className = 'item-content' style = {{ width: 230 }}>
                    <TextField
                        disabled
                        select
                        label = 'Seleccione'
                        value = { typeService }
                        onChange = { handleChange }
                    >
                    {
                        typeOfServices.map( ( option ) => (
                            <MenuItem key = { option.value } value = { option.value }>
                                { option.label }
                            </MenuItem>
                        ))
                    }
                    </TextField>
                    <p
                        onClick = { () => copy(
                            `
                            <TextField
                                disabled
                                select
                                label = 'Seleccione'
                                value = { typeService }
                                onChange = { handleChange }
                            >
                            {
                                typeOfServices.map( ( option ) => (
                                    <MenuItem key = { option.value } value = { option.value }>
                                        { option.label }
                                    </MenuItem>
                                ))
                            }
                            </TextField>
                            `
                        )}
                    >Disabled select</p>
                </div>
                <div className = 'item-content' style = {{ width: 230 }}>
                    <TextField
                        error
                        select
                        label = 'Seleccione'
                        value = { typeService }
                        onChange = { handleChange }
                    >
                    {
                        typeOfServices.map( ( option ) => (
                            <MenuItem key = { option.value } value = { option.value }>
                                { option.label }
                            </MenuItem>
                        ))
                    }
                    </TextField>
                    <p
                        onClick = { () => copy(
                            `
                            <TextField
                                error
                                select
                                label = 'Seleccione'
                                value = { typeService }
                                onChange = { handleChange }
                            >
                            {
                                typeOfServices.map( ( option ) => (
                                    <MenuItem key = { option.value } value = { option.value }>
                                        { option.label }
                                    </MenuItem>
                                ))
                            }
                            </TextField>
                            `
                        )}
                    >Error select</p>
                </div>
            </div>

            <hr />

            <h3 className = 'component-subtitle'>Select Autocomplete</h3>

            <div className = 'items-container'>
                <Autocomplete
                    disablePortal
                    options = {departamentos}
                    sx = {{ width: 300 }}
                    noOptionsText = 'No se encontraron resultados'
                    renderInput = {(params) => 
                        <TextField {...params} label = "Ciudades" />
                    }
                />
            </div>

            <hr />

            <h3 className = 'component-subtitle'>TextArea</h3>

            <div className = 'items-container'>
                <div className = 'item-content' style = {{ width: 300 }}>
                    <TextField
                        label = 'Descripción'
                        multiline
                        minRows = { 3 }
                        value = { value }
                        onChange = { handleChangeInput }
                    />
                    <p
                        onClick = { () => copy(
                            `
                            <TextField
                                label = 'Descripción'
                                multiline
                                minRows = { 3 }
                                value = { value }
                                onChange = { handleChangeInput }
                            />
                            `
                        )}
                    >Normal textarea</p>
                </div>
                <div className = 'item-content' style = {{ width: 300 }}>
                    <TextField
                        disabled
                        label = 'Descripción'
                        multiline
                        minRows = { 3 }
                        value = { value }
                        onChange = { handleChangeInput }
                    />
                    <p
                        onClick = { () => copy(
                            `
                            <TextField
                                disabled
                                label = 'Descripción'
                                multiline
                                minRows = { 3 }
                                value = { value }
                                onChange = { handleChangeInput }
                            />
                            `
                        )}
                    >Disabled textarea</p>
                </div>
                <div className = 'item-content' style = {{ width: 300 }}>
                    <TextField
                        error
                        label = 'Descripción'
                        multiline
                        minRows = { 3 }
                        value = { value }
                        onChange = { handleChangeInput }
                    />
                    <p
                        onClick = { () => copy(
                            `
                            <TextField
                                error
                                label = 'Descripción'
                                multiline
                                minRows = { 3 }
                                value = { value }
                                onChange = { handleChangeInput }
                            />
                            `
                        )}
                    >Error textarea</p>
                </div>
            </div>

            <hr />

            <h3 className = 'component-subtitle'>Radio Buttons</h3>
            
            <div className = 'items-container'>
                <div className = 'item-content' style = {{ marginRight: 80 }}>
                    <FormControl>
                        <FormLabel>Seleccionar color</FormLabel>
                        <RadioGroup
                            name = 'radio-buttons-group'
                            defaultValue = 'red'
                        >
                            <FormControlLabel 
                                label = 'Rojo'
                                value = 'red'
                                control ={ <Radio />}
                            />
                            <FormControlLabel 
                                label = 'Azul'
                                value = 'blue'
                                control ={ <Radio />}
                            />
                            <FormControlLabel 
                                label = 'Verde'
                                value = 'green'
                                control ={ <Radio />}
                            />
                            <FormControlLabel 
                                label = 'Naranja'
                                value = 'orange'
                                control ={ <Radio />}
                            />
                        </RadioGroup>
                    </FormControl>
                    <p className = 'mt-4' onClick = { () => copy(
                        `
                        <FormControl>
                            <FormLabel>Seleccionar color</FormLabel>
                            <RadioGroup
                                name = 'radio-buttons-group'
                                defaultValue = 'red'
                            >
                                <FormControlLabel 
                                    label = 'Rojo'
                                    value = 'red'
                                    control ={ <Radio />}
                                />
                                <FormControlLabel 
                                    label = 'Azul'
                                    value = 'blue'
                                    control ={ <Radio />}
                                />
                                <FormControlLabel 
                                    label = 'Verde'
                                    value = 'green'
                                    control ={ <Radio />}
                                />
                                <FormControlLabel 
                                    label = 'Naranja'
                                    value = 'orange'
                                    control ={ <Radio />}
                                />
                            </RadioGroup>
                        </FormControl>
                        `
                    ) }>Vertical radiobutton</p>
                </div>
                <div className = 'item-content'>
                    <FormControl>
                        <FormLabel>Seleccionar color</FormLabel>
                        <RadioGroup
                            row
                            name = 'radio-buttons-group'
                            defaultValue = 'red'
                        >
                            <FormControlLabel 
                                label = 'Rojo'
                                value = 'red'
                                control ={ <Radio />}
                            />
                            <FormControlLabel 
                                label = 'Azul'
                                value = 'blue'
                                control ={ <Radio />}
                            />
                            <FormControlLabel 
                                label = 'Verde'
                                value = 'green'
                                control ={ <Radio />}
                            />
                            <FormControlLabel 
                                label = 'Naranja'
                                value = 'orange'
                                control ={ <Radio />}
                            />
                        </RadioGroup>
                    </FormControl>
                    <p className = 'mt-4' onClick = { () => copy(
                        `
                        <FormControl>
                            <FormLabel>Seleccionar color</FormLabel>
                            <RadioGroup
                                row
                                name = 'radio-buttons-group'
                                defaultValue = 'red'
                            >
                                <FormControlLabel 
                                    label = 'Rojo'
                                    value = 'red'
                                    control ={ <Radio />}
                                />
                                <FormControlLabel 
                                    label = 'Azul'
                                    value = 'blue'
                                    control ={ <Radio />}
                                />
                                <FormControlLabel 
                                    label = 'Verde'
                                    value = 'green'
                                    control ={ <Radio />}
                                />
                                <FormControlLabel 
                                    label = 'Naranja'
                                    value = 'orange'
                                    control ={ <Radio />}
                                />
                            </RadioGroup>
                        </FormControl>
                        `
                    ) }>Row radiobutton</p>
                </div>
            </div>

            <hr />

            <h3 className = 'component-subtitle'>Checkbox</h3>

            <div className = 'items-container'>
                <div className = 'item-content'>
                    <Checkbox defaultChecked />
                    <p onClick = { () => copy(
                        `
                        <Checkbox defaultChecked />
                        `
                    )}>Checked checkbox</p>
                </div>
                <div className = 'item-content'>
                    <Checkbox />
                    <p onClick = { () => copy(
                        `
                        <Checkbox />
                        `
                    )}>Normal checkbox</p>
                </div>
                <div className = 'item-content'>
                    <Checkbox disabled />
                    <p onClick = { () => copy(
                        `
                        <Checkbox disabled />
                        `
                    )}>Disabled checkbox</p>
                </div>
                <div className = 'item-content'>
                    <Checkbox disabled checked />
                    <p onClick = { () => copy(
                        `
                        <Checkbox disabled checked />
                        `
                    )}>Disabled checked</p>
                </div>
                <div className = 'item-content'>
                    <FormGroup>
                        <FormControlLabel
                            label = 'Checkbox'
                            control = {
                                <Checkbox defaultChecked />
                            }
                        />
                        <FormControlLabel
                            disabled
                            label = 'Disabled'
                            control = {
                                <Checkbox />
                            }
                        />
                    </FormGroup>
                    <p onClick = { () => copy(
                        `
                        <FormGroup>
                            <FormControlLabel
                                label = 'Checkbox'
                                control = {
                                    <Checkbox defaultChecked />
                                }
                            />
                            <FormControlLabel
                                disabled
                                label = 'Disabled'
                                control = {
                                    <Checkbox />
                                }
                            />
                        </FormGroup>
                        `
                    )}>Group Checkbox</p>
                </div>
            </div>

            <hr />
                        
            <h3 className = 'component-subtitle'>Badges</h3>

            <div className = 'items-container'>
                {
                    classesName.filter( x => x !== 'White' && x !== 'Link' ).map( c => {
                        let name = c.toLowerCase();
                        return (
                            <div className = 'item-content' key = { c }>
                                <span
                                    className = { `mpi-badge-${ name }` }
                                    onClick = { () => copy(`<span className='mpi-badge-${ name }'>${ c }</span>`) }
                                    style = {{ //no included by default
                                        cursor: 'pointer'
                                    }}
                                >{ c }</span>
                                <p
                                    onClick = { () => copy( `mpi-badge-${ name }`) }
                                >{ `mpi-badge-${ name }` }</p>
                            </div>
                        )
                    })
                }
            </div>
            


            <div className='container mb-5'>
                <button className='mpi-btn-danger'>Presiona</button>
                <button className='mpi-btn-outline-success'>Success</button>
                
                <TextField 
                    label = 'Usuario'
                    variant = 'outlined'
                />
                        
            </div>
        </div>
            
    )
}
                            