export const publishServiceStepsType = {
  typeSelection: "typeSelection",
  personalInfo: "personalInfo",
  businessInfo: "businessInfo",
  serviceInfo: "serviceInfo",
  serviceLocation: "serviceLocation",
  uploadImages: "uploadImages",
  payOptions: "payOptions",
    finished: "finished",
    message: "message",
};

export const publishServiceType = {
  independent: "independent",
  business: "business",
};
