import { types } from "../types/Types";

export const AppReducer = (state = {}, action) => {
  switch (action.type) {
    /**********************/
    /*   AUTHENTICATION   */
    /**********************/
    case types.signIn:
      return {
        ...state,
        logged: true,
      };
    case types.signOut:
      return {
        ...state,
        logged: false,
      };
    /********************/
    /*   LOGGED USER    */
    /********************/
    case types.setLoggedUser:
      return {
        ...state,
        loggedUser: action.payload,
      };

    /********************/
    /*  MODIFIED USER   */
    /********************/
    case types.setModifiedUser:
      return {
        ...state,
        modifiedUser: action.payload,
      };

    /****************/
    /*   LOCATION   */
    /****************/
    case types.setCurrentLatLng:
      return {
        ...state,
        currentLatLng: action.payload,
      };
    /****************/
    /*   SERVICES   */
    /****************/
    case types.setServices:
      return {
        ...state,
        services: action.payload,
      };
    case types.setUserServices:
      return {
        ...state,
        userServices: action.payload,
      };
    case types.setSearchServiceText:
      return {
        ...state,
        searchText: action.payload,
      };
    case types.setServiceSelected:
      return {
        ...state,
        serviceSelected: action.payload,
      };
    case types.setNewService:
      return {
        ...state,
        newService: action.payload,
      };
    /****************************/
    /*   SERVICE - DEPARTMENT   */
    /****************************/
    case types.setDepartments:
      return {
        ...state,
        departments: action.payload,
      };
    case types.setDistricts:
      return {
        ...state,
        districts: action.payload,
      };
    case types.setDepartmentId:
      return {
        ...state,
        departmentId: action.payload,
      };
    case types.setDepartmentName:
      return {
        ...state,
        departmentName: action.payload,
      };
    case types.setDepartmentClassName:
      return {
        ...state,
        departmentClassName: action.payload,
      };
    /****************************/
    /*    SERVICE - DISTRICT    */
    /****************************/
    case types.setDistrictId:
      return {
        ...state,
        districtId: action.payload,
      };
    case types.setDistrictName:
      return {
        ...state,
        districtName: action.payload,
      };
    /****************************/
    /*     SERVICE - IMAGES     */
    /****************************/
    case types.setServiceImages:
      return {
        ...state,
        serviceImages: action.payload,
      };
    /****************/
    /*   CATEGORY   */
    /****************/
    case types.setCategoryId:
      return {
        ...state,
        categoryId: action.payload,
      };
    case types.setSubcategoryId:
      return {
        ...state,
        subcategoryId: action.payload,
      };

    case types.setSubCategories:
      return {
        ...state,
        subCategories: action.payload,
      };

    /****************/
    /*   COMPANY    */
    /****************/
    case types.setCompany:
      return {
        ...state,
        company: action.payload,
      };
    /****************/
    /*   LOADING    */
    /****************/
    case types.setModalLoading:
      return {
        ...state,
        modalLoading: action.payload,
      };

    case types.setMode:
      return {
        ...state,
        mode: action.payload,
      };
    case types.setCollapsed:
      return {
        ...state,
        collapsed: action.payload,
        isServiceDetailOpened: !action.payload
          ? false
          : state.isServiceDetailOpened,
        isServiceDetailExtended: !action.payload
          ? false
          : state.isServiceDetailExtended,
      };
    case types.setDetailServiceOpened:
      return {
        ...state,
        isServiceDetailOpened: action.payload,
        collapsed: action.payload ? true : state.collapsed,
        isServiceShareOpened: !action.payload
          ? false
          : state.isServiceShareOpened,
      };
    case types.setDetailServiceExtended:
      return {
        ...state,
        isServiceDetailExtended: action.payload,
      };
    case types.setShareOpened:
      return {
        ...state,
        isServiceShareOpened: action.payload,
      };
    case types.setPanelSideBarCollapsed:
      return {
        ...state,
        isPanelSideBarCollapsed: action.payload,
      };
    case types.setConfirmModalOpen:
      return {
        ...state,
        confirmModalOpen: action.payload,
      };
    case types.setModalRegisterCodeOpen:
      return {
        ...state,
        modalRegisterCodeOpen: action.payload,
      };
    case types.setModalRecoverPasswordOpen:
      return {
        ...state,
        modalRecoverPasswordOpen: action.payload,
      };
    case types.setModalOkOpen:
      return {
        ...state,
        modalOkOpen: action.payload,
      };
    case types.setPublishServiceModalOpen:
      return {
        ...state,
        publishServiceModalOpen: action.payload,
      };
    case types.setPublishServiceType:
      return {
        ...state,
        publishServiceType: action.payload,
      };
    case types.setPublishServiceStep:
      return {
        ...state,
        publishServiceStep: action.payload,
      };
    case types.setDistrictsAll:
        return {
            ...state,
            districtsAll: action.payload,
          };
    case types.setModalRegisterComent:
        return {
            ...state,
            modalRegisterComent: action.payload,
          };
      case types.setModalOkResena:
          return {
              ...state,
              modalOkResena: action.payload,
          };
      case types.setResena:
          return {
              ...state,
              resena: action.payload,
          };
      case types.setUsersAll:
          return {
              ...state,
              usersAll: action.payload,
          };
      case types.setModalInicioSesion:
        return {
            ...state,
            modalInicioSesion: action.payload,
        };
      case types.setImageReseAll:
        return {
            ...state,
            imageReseAll: action.payload,
        };
      case types.setNewServiceResena:
        return {
            ...state,
            newServiceResena: action.payload,
          };
      case types.setServiceSelectedAux:
        return {
            ...state,
            serviceSelectedAux: action.payload,
          };
      case types.setModalListResena:
          return {
              ...state,
              modalListResena: action.payload,
          };
      case types.setServiceList:
          return {
              ...state,
              serviceList: action.payload,
          };
      case types.setIsImageAvaible:
          return{
            ...state,
            isImageAvaible:action.payload
          }
      case types.setServicesAll:
        return{
          ...state,
          servicesAll:action.payload
        }
      case types.setImagenesAudio:
        return{
          ...state,
          imagenesAudio:action.payload
        }
      case types.setAudioConfig:
        return{
          ...state,
          audioConfig:action.payload
        }
      case types.setIsActivoService:
        return{
          ...state,
          isActivoService:action.payload
        }
      case types.setNewServiceCopy:
        return{
          ...state,
          newServiceCopy:action.payload
        }
      case types.setImagenesPreloader:
        return{
          ...state,
          imagenesPreloader:action.payload
        }
      case types.setActiveLogged:
        return{
          ...state,
          activeLogged:action.payload
        }
      case types.setActiveGeolocalizacion:
        return{
          ...state,
          activeGeolocalizacion:action.payload
        }
      case types.setActiveServicesAll:
        return{
          ...state,
          activeServicesAll:action.payload
        }
        default:
            return state;
    }
};
