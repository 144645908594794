
import { useEffect } from "react";
import { useAppState } from "./useAppState";
import { imageChapter, imageMap, imageMapAvatar } from "../components/panel/content/courses/audioCourse/ImagenPreLoaderAudio";
import logo from "../assets/images/global/logoMappi-03.png";
import banner1 from '../assets/images/auth/banner1.svg';
import banner2 from '../assets/images/auth/banner2.svg';
import logo1 from '../assets/images/global/logo4.png';
import logo2 from '../assets/images/global/logoprincipal.png';
import logo3 from '../assets/images/global/logoReduced.svg';
import logo4 from '../assets/images/global/logoMappi-03.png';


import location from '../assets/images/auth/location1.svg';
import user from '../assets/images/auth/user2.svg';
import whatsapp from '../assets/images/auth/whatsapp.svg';

import info1 from '../assets/images/auth/info1.svg';
import info2 from '../assets/images/auth/info2.svg';
import info3 from '../assets/images/auth/info3.svg';
import info4 from '../assets/images/auth/info4.svg';

const useImagePreloading = () => {
  const { setImagenesAudio, setImagenesPreloader, imagenesPreloader } = useAppState();
  
  useEffect(() => {
    const preloadImages = (imageUrls) => {
      const images = {};
      for (const key in imageUrls) {
        images[key] = new Image();
        images[key].src = imageUrls[key];
      }
      return images;
    };

    const imagesAudio = {
      imageMap: preloadImages(imageMap),
      imageMapAvatar: preloadImages(imageMapAvatar),
      imageChapter: preloadImages(imageChapter)
    };

    const logoImage = new Image();
    const banner1Image = new Image();
    const banner2Image = new Image();
    const logo1Image = new Image();
    const logo2Image = new Image();
    const logo3Image = new Image();
    const logo4Image = new Image();

    const locationImage = new Image();
    const userImage = new Image();
    const whatsappImage = new Image();

    const info1Image = new Image();
    const info2Image = new Image();
    const info3Image = new Image();
    const info4Image = new Image();


    logoImage.src = logo;
    banner1Image.src = banner1;
    banner2Image.src = banner2;
    logo1Image.src = logo1;
    logo2Image.src = logo2;
    logo3Image.src = logo3;
    logo4Image.src = logo4;

    locationImage.src = location;
    userImage.src = user;
    whatsappImage.src = whatsapp;

    info1Image.src = info1;
    info2Image.src = info2;
    info3Image.src = info3;
    info4Image.src = info4;


    // Actualizar el estado con la imagen del logo
    setImagenesPreloader({ 
      ...imagenesPreloader, 
      logo: logoImage, 
      banner1: banner1Image, 
      banner2: banner2Image,
      logo1: logo1Image,
      logo2: logo2Image,
      logo3: logo3Image,
      logo4: logo4Image,
      location: locationImage,
      user: userImage,
      whatsapp: whatsappImage,
      info1:info1Image,
      info2:info2Image,
      info3:info3Image,
      info4:info4Image,
    });

    // Actualizar el estado con las imágenes de audio
    setImagenesAudio(imagesAudio);

  }, []);

};

export default useImagePreloading;
