import { useRef, useState, useEffect } from "react";
import { api } from "../api/apiConfig";
import { useAppState } from "./useAppState";
import { useUbigeo } from "./useUbigeo";
import { useMap } from "./useMap";
import { calculateDistanceBetweenCoordinates } from "../utils/utils";
import { useResena } from "./useResena";
import { usePublishService } from "./usePublishService";
import { useCompany } from "./useCompany";
import { useUser } from "./useUser";

export const useService = () => {
    const {
        services,
        setServices,
        departmentId,
        searchText,
        handleServiceDetailVisibility,
        serviceSelected,
        setCollapsed,
        setServiceSelected,
        setNewService,
        setModalOkOpen,
        newServices,
        currentLatLng,
        setUserServices,
        userServices,
        categoryId,
        subCategories,
        newService,
        newServiceResena,
        setNewServiceResena,
        setServiceSelectedAux,
        serviceSelectedAux,
        setServiceList,
        departments,
        setServicesAll,
        servicesAll,
        districtsAll,
        isActivoService,
        setIsActivoService,
        loggedUser,
        setActiveGeolocalizacion,
        setActiveServicesAll,
        SetservicesAll
    } = useAppState();
    const { handleClassNameDepartment, getDepartments } = useUbigeo();
    const { center } = useMap();
    const { getResenaByService } = useResena();
    const {getCompanies} = useCompany();
    const { getUser} = useUser(); 
    // const { getArchiveByService } = usePublishService();

    const [loading, setLoading] = useState(false);
    const [servicesFeatured, setServicesFeatured] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const isMounted = useRef(true);
    const servicesSearched = useRef(false);

    const getServices = (isSearchInput = false,getAllDepartments = false) => {
        if (!isSearchInput || (isSearchInput && searchText !== "")) {
            if (departmentId !== 0) {
                try {
                    isSearchInput && setLoading(true);
                    api()
                        .get(
                            `Servicios/PorUbigeo/${departmentId}/${searchText === "" ? 0 : searchText}`
                        )
                        .then(async (resp) => {
                            const servicesWithReviews = [];
                            for (const service of resp.data) {
                                const reviews = await new Promise((resolve, reject) => {
                                    getResenaByService(service.id, (resolve));
                                });
                                service.reviews = reviews;
                                servicesWithReviews.push(service);
                            }
                            setLoading(false);
                            if (servicesWithReviews.length > 0) {
                                for (let i in servicesWithReviews) {
                                    servicesWithReviews[i].distance = calculateDistanceBetweenCoordinates(
                                        currentLatLng.latitude,
                                        currentLatLng.longitude,
                                        servicesWithReviews[i].latitud,
                                        servicesWithReviews[i].longitud
                                    );
                                }
                                setServices(servicesWithReviews);
                                handleServiceDetailVisibility();
                                servicesSearched.current = isSearchInput;
                            } else {
                                // setModalOkOpen(true);
                                getNearServices();
                            }
                        });
                } catch (error) {
                    console.error(error);
                }
            } else {
                handleClassNameDepartment();
            }
        }
    };

    const getServicesAll = (getAllDepartments = false) => {
        let departmentIds = [];
        if (getAllDepartments) {
            departmentIds = departments.map(department => department.id);
        }
    
        const promises = [];
        for (const id of departmentIds) {
            promises.push(
                api().get(`Servicios/PorUbigeo/${id}/0`)
                    .then(response => response.data)
                    .catch(error => {
                        console.error("Error fetching services:", error);
                        return [];
                    })
            );
        }
    
        return Promise.all(promises)
            .then(async allServices => {
                const servicesWithReviews = [];
                const mergedServices = allServices.reduce((accumulator, current) => accumulator.concat(current), []);
                    for (const service of mergedServices) {
                        const reviews = await new Promise((resolve, reject) => {
                            getResenaByService(service.id, (resolve));
                        });
                        service.reviews = reviews;
                        servicesWithReviews.push(service);
                    }
                    for (let i in servicesWithReviews) {
                        servicesWithReviews[i].distance = calculateDistanceBetweenCoordinates(
                            currentLatLng.latitude,
                            currentLatLng.longitude,
                            servicesWithReviews[i].latitud,
                            servicesWithReviews[i].longitud
                        );
                    }
                    setServicesAll(servicesWithReviews);
            })
            .catch(error => {
                console.error("Error fetching all services:", error);
            });
    };

    const getServiceByCategoria = (newCategoryId) => {
        const districtsFilter = districtsAll.filter((district)=>district.idPadre === departmentId);
        const districtIds = districtsFilter.map(district => district.id);
        const allServices = servicesAll.filter(service => districtIds.includes(service.idUbigeo));

        if (newCategoryId !== 0) {
            const servicesFilters = subCategories.filter((x) => x.fatherId === newCategoryId);
            const filterValues = servicesFilters.map((filter) => filter.value);
            const filteredServices = allServices.filter((service) => filterValues.includes(service.idCategoria));
            const newServicesFilter = getDistanceServices(filteredServices);

            if (allServices.length === 0) {
                setServices(allServices);
            } else {
                if (newServicesFilter.length === 0) {
                    const newservicesFilters = subCategories.filter((x) => x.fatherId !== newCategoryId);
                    const filterValues = newservicesFilters.map((filter) => filter.value);
                    let filteredServicess = allServices.filter((service) => filterValues.includes(service.idCategoria));
                    const newServicesFilter = getDistanceServices(filteredServicess);
                    if (newServicesFilter.length > 0) {
                        setModalOkOpen(true);
                        setServices(newServicesFilter);
                    } else {
                        setServices(filteredServices);
                    }
                } else {
                    const newServicesFilter = getDistanceServices(filteredServices);
                    setServices(newServicesFilter);
                }
            }
        } else {
            setServices([...allServices]);
        }
    };
    
    const getServiceBySubCategoria = (newCategoryId, newSubCategory) => {
        const districtsFilter = districtsAll.filter((district)=>district.idPadre === departmentId);
        const districtIds = districtsFilter.map(district => district.id);
        const allServices = servicesAll.filter(service => districtIds.includes(service.idUbigeo));

        if (newCategoryId !== 0) {
            const servicesFilters = subCategories.find(
                (x) => x.value === newSubCategory
            );

            if (servicesFilters) {
                const filteredServices = allServices.filter((service) =>
                    servicesFilters.label.includes(service.nombre)
                );

                const newfilteredServices = getDistanceServices(filteredServices);

                if (newfilteredServices.length === 0) {
                    setModalOkOpen(true);
                    const newServicesFilter = getDistanceServices(allServices);
                    // getNearServices();
                    setServices(newServicesFilter);
                } else {
                    const newServicesFilter = getDistanceServices(newfilteredServices);
                    if (newServicesFilter.length === 0) {
                        setModalOkOpen(true);
                        setServices(newfilteredServices);
                    } else {
                        const newServicesFilter = getDistanceServices(newfilteredServices);
                        setServices(newServicesFilter);
                    }
                }
            }
        } else {
            setServices([...allServices]);
        }
    };


    const getServicesBySubcategory = (idSubcategory) => {
        var filteredServices =
            idSubcategory === null || idSubcategory === 0
                ? services
                : services.filter((s) => s.idCategoria === idSubcategory);
        if (serviceSelected) {
            var foundService = filteredServices.find(
                (x) => x.id === serviceSelected.id
            );
            if (!foundService) {
                setCollapsed(false);
                setServiceSelected(null);
            }
        }
        if (filteredServices.length === 0) {
            //setModalOkOpen(true);
            filteredServices = getDistanceServices(services);
            return filteredServices;
        }
        return filteredServices;
    };

    const getNearServices = () => {
        setLoading(true);
        api()
            .get(`Servicios/ObtenerCercanos/${center.lat}/${center.lng}/${5000}`)
            .then((resp) => {
                setLoading(false);
                const data = resp.data;
                const userLastData = {};

                data.forEach((item) => {
                    const userId = item.idUsuario;
                    if (
                        !userLastData[userId] ||
                        item.idLogo > userLastData[userId].idLogo ||
                        item.idPortada > userLastData[userId].idPortada
                    ) {
                        userLastData[userId] = {
                            ...item,
                        };
                    }
                });
                const resultArray = Object.values(userLastData);
                setServices(resultArray);
            })
            .catch((error) => console.error(error));
    };

    

    const getDistanceServices = (servicios) => {
        const { lat, lng } = center;
        const radius = 5; // radio de búsqueda en km
        return servicios.filter(
            (s) =>
                calculateDistanceBetweenCoordinates(lat, lng, s.latitud, s.longitud) <=
                radius
        );
    };

    const getServiceFeatured = () => {
        api()
            .get("/Servicios/ObtenerPremium")
            .then((resp) => {
                setServicesFeatured(resp.data);
            })
            .catch((error) => console.error(error));
    };

    const getServicesbyUser = (userId, includeInactive = false) => {
        let endpoint = "/Servicios/ObtenerPorUsuario/" + userId;
        if (includeInactive) {
            endpoint += "?incluirInactivos=true";
        }
    
        api()
            .get(endpoint)
            .then((resp) => {
                const services = resp.data;
                if (includeInactive) {
                    if (services.length > 0) {
                        const hasOtherCategoryService = services.some(serv => serv.idCategoria !== 14);
                        if (hasOtherCategoryService) {
                            setIsActivoService(true);
                        } else {
                            setIsActivoService(false);
                        }
                    } else {
                        setIsActivoService(false);
                    }
                } else {
                    const updatedServices = [];
                    const processService = (service) => {
                        return new Promise((resolve, reject) => {
                            structureServiceByIdUser(service.id, detailedService => {
                                const updatedService = { ...service };
                                for (const key in detailedService) {
                                    if (
                                        (updatedService[key] === null || updatedService[key] === "" || updatedService[key] === 0) &&
                                        (updatedService[key] !== detailedService[key])
                                    ) {
                                        updatedService[key] = detailedService[key];
                                    }
                                }
                                updatedServices.push(updatedService);
                                resolve();
                            }, true);
                        });
                    };
    
                    Promise.all(services.map(processService))
                        .then(() => {
                            setServiceList(updatedServices);
                            setUserServices(updatedServices);
                        })
                        .catch((error) => console.error(error));
                }
            })
            .catch((error) => console.error(error));
    };
    
    
    

    const deleteServicebyUser = (Id) => {

        api()
            .get("/Servicios/eliminar/" + Id)
            .then((resp) => {
                getDepartments(true);
                // getServicesAll(true);
                // setActiveServicesAll(true);
                // setActiveServices(false);
                // getUser(loggedUser?.id);
                // getCompanies();
                const newServices = servicesAll.filter((servicio) => servicio.id != Id);
                setServicesAll(newServices)
                // setServices(newServices);
                // getServicesbyUser(loggedUser?.id,true);
                // getServicesbyUser(loggedUser?.id);
                setUserServices(userServices.filter((servicio) => servicio.id != Id));
            })
            .catch((error) => console.error(error));
    };

    const structureServiceByIdUser = (Id, callback = null, active = false) => {
        api()
            .get("/Servicios/EstructuraPorId/" + Id)
            .then((resp) => {
                const resenaData = resp.data;
                if (callback) {
                    callback(resenaData);
                }
                if(active === false){
                    setNewService({
                        ...newServices,
                        id: resp.data.id,
                        address: resp.data.direccion,
                        category: { value: resp.data.idCategoria, label: resp.data.nombre },
                        description: resp.data.descripcion,
                        hasDelivery: {
                            value: resp.data.tieneDelivery,
                            label:
                                resp.data.tieneDelivery === true
                                    ? "Con Delivery"
                                    : "Sin Delivery",
                        },
                        schedule: {
                            value: (resp.data.horario == null) | 0 ? "L" : "D",
                            label:
                                (resp.data.horario == null) | 0
                                    ? "Siempre abierto"
                                    : "Hora de atencion",
                        },
                        horaAten: resp.data.horario,
                        fotoServicios: resp.data.fotoServicios,
                        idUbigeo: resp.data.idUbigeo,
                        montoCulqi: resp.data.montoCulqi,
                        latitude: resp.data.latitud,
                        longitude: resp.data.longitud,
                        nombre: resp.data.nombreServicio,
                        reviews: resp.data.reviews,
                    });
                }
            })
            .catch((error) => console.error(error));
    };

    return {
        getServices,
        getServicesbyUser,
        getServicesBySubcategory,
        modalShow,
        setModalShow,
        services,
        loading,
        setLoading,
        searched: servicesSearched.current,
        getServiceFeatured,
        servicesFeatured,
        deleteServicebyUser,
        structureServiceByIdUser,
        getServiceByCategoria,
        getServiceBySubCategoria,
        getServicesAll,
        getDistanceServices
    };
};
