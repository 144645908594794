import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

// Importar Firebase
import * as firebase from 'firebase/app';
import 'firebase/auth';

// Cargar el archivo .env correspondiente al entorno
require('dotenv').config();

//Configurar firebase
const firebaseConfig = {
    apiKey: "AIzaSyDU6UbEkMMeeDi8C6Vt6KCUejzDy__K5p4",
    authDomain: "mappi-398817.firebaseapp.com",
    projectId: "mappi-398817",
    storageBucket: "mappi-398817.appspot.com",
    messagingSenderId: "46954292776",
    appId: "1:46954292776:web:88e5262d4a3d9da4a3f71d",
    measurementId: "G-MFM2GS2PL8"
};
//inicializa
firebase.initializeApp(firebaseConfig);

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();