import { types } from "../../types/Types";

/**********************/
/*   AUTHENTICATION   */
/**********************/

export const signIn = () => ({
    type: types.signIn,
});

export const signOut = () => ({
    type: types.signOut,
});

/**********************/
/*     LOGGED USER    */
/**********************/

export const setLoggedUser = (loggedUser) => ({
    type: types.setLoggedUser,
    payload: loggedUser,
});

/**********************/
/*    MODIFIED USER   */
/**********************/

export const setModifiedUser = (modifiedUser) => ({
    type: types.setModifiedUser,
    payload: modifiedUser,
});

/****************/
/*   LOCATION   */
/****************/

export const setCurrentLatLng = (LatLgn) => ({
    type: types.setCurrentLatLng,
    payload: LatLgn,
});

/****************/
/*   SERVICES   */
/****************/

export const setServices = (services) => ({
    type: types.setServices,
    payload: services,
});

export const setUserServices = (userServices) => ({
    type: types.setUserServices,
    payload: userServices,
});

export const setSearchServiceText = (searchText) => ({
    type: types.setSearchServiceText,
    payload: searchText,
});

export const setServiceSelected = (service) => ({
    type: types.setServiceSelected,
    payload: service,
});

export const setNewService = (service) => ({
    type: types.setNewService,
    payload: service,
});

/****************************/
/*   SERVICE - DEPARTMENT   */
/****************************/

export const setDepartments = (departments) => ({
    type: types.setDepartments,
    payload: departments,
});

export const setDistricts = (districts) => ({
    type: types.setDistricts,
    payload: districts,
});

export const setDepartmentId = (departmentId) => ({
    type: types.setDepartmentId,
    payload: departmentId,
});

export const setDepartmentName = (departmentName) => ({
    type: types.setDepartmentName,
    payload: departmentName,
});

export const setDepartmentClassName = (className) => ({
    type: types.setDepartmentClassName,
    payload: className,
});

/****************************/
/*    SERVICE - DISTRICT    */
/****************************/

export const setDistrictId = (districtId) => ({
    type: types.setDepartmentId,
    payload: districtId,
});

export const setDistrictName = (districtName) => ({
    type: types.setDepartmentName,
    payload: districtName,
});

/****************************/
/*     SERVICE - IMAGES     */
/****************************/

export const setServiceImages = (serviceImages) => ({
    type: types.setServiceImages,
    payload: serviceImages,
});

/****************/
/*   CATEGORY   */
/****************/

export const setCategoryId = (categoryId) => ({
    type: types.setCategoryId,
    payload: categoryId,
});

export const setSubcategoryId = (subcategoryId) => ({
    type: types.setSubcategoryId,
    payload: subcategoryId,
});

export const setSubCategories = (subCategories) => ({
    type: types.setSubCategories,
    payload: subCategories,
});

/****************/
/*   COMPANY    */
/****************/

export const setCompany = (company) => ({
    type: types.setCompany,
    payload: company,
});

/****************/
/*   LOADING    */
/****************/

export const setModalLoading = (modalLoading) => ({
    type: types.setModalLoading,
    payload: modalLoading,
});

/**********************/
/*   REGISTER CODE    */
/**********************/
export const setModalRegisterCodeOpen = (modalRegisterCodeOpen) => ({
    type: types.setModalRegisterCodeOpen,
    payload: modalRegisterCodeOpen,
});

/**********************/
/*   RECOVER PASSWORD CODE    */
/**********************/
export const setModalRecoverPasswordOpen = (modalRecoverPasswordOpen) => ({
    type: types.setModalRecoverPasswordOpen,
    payload: modalRecoverPasswordOpen,
});

/**********************/
/*   OKMODAL   */
/**********************/
export const setModalOkOpen = (modalOkOpen) => ({
    type: types.setModalOkOpen,
    payload: modalOkOpen,
});

export const setMode = (value) => ({
    type: types.setMode,
    payload: value,
});

export const setDetailServiceOpened = (value) => ({
    type: types.setDetailServiceOpened,
    payload: value,
});

export const setCollapsed = (value) => ({
    type: types.setCollapsed,
    payload: value,
});

export const setDetailServiceExtended = (value) => ({
    type: types.setDetailServiceExtended,
    payload: value,
});

export const setShareOpened = (value) => ({
    type: types.setShareOpened,
    payload: value,
});

export const setPanelSideBarCollapsed = (value) => ({
    type: types.setPanelSideBarCollapsed,
    payload: value,
});

export const setConfirmModalOpen = (value) => ({
    type: types.setConfirmModalOpen,
    payload: value,
});

export const setPublishServiceModalOpen = (value) => ({
    type: types.setPublishServiceModalOpen,
    payload: value,
});

export const setPublishServiceType = (value) => ({
    type: types.setPublishServiceType,
    payload: value,
});

export const setPublishServiceStep = (value) => ({
    type: types.setPublishServiceStep,
    payload: value,
});

export const setDistrictsAll = (value) => ({
    type: types.setDistrictsAll,
    payload: value,
});

export const setModalRegisterComent = (modalRegisterComent) => ({
    type: types.setModalRegisterComent,
    payload: modalRegisterComent,
});

export const setModalOkResena = (modalOkResena) => ({
    type: types.setModalOkResena,
    payload: modalOkResena,
});

export const setResena = (resena) => ({
    type: types.setResena,
    payload: resena,
});

export const setUsersAll = (usersAll) => ({
    type: types.setUsersAll,
    payload: usersAll,
});

export const setModalInicioSesion = (ModalInicioSesion) => ({
    type: types.setModalInicioSesion,
    payload: ModalInicioSesion,
});

export const setImageReseAll = (imageReseAll) => ({
    type: types.setImageReseAll,
    payload: imageReseAll,
})

export const setNewServiceResena = (newServiceResena) => ({
    type: types.setNewServiceResena,
    payload: newServiceResena,
})

export const setServiceSelectedAux = (serviceSelectedAux) => ({
    type: types.setServiceSelectedAux,
    payload: serviceSelectedAux,
})

export const setModalListResena = (modalListResena) => ({
    type: types.setModalListResena,
    payload: modalListResena,
})

export const setServiceList = (serviceList) => ({
    type: types.setServiceList,
    payload: serviceList,
})

export const setIsImageAvaible = (isImageAvaible) => ({
    type:types.setIsImageAvaible,
    payload: isImageAvaible
})

export const setServicesAll = (servicesAll) => ({
    type:types.setServicesAll,
    payload: servicesAll
})

export const setImagenesAudio = (imagenesAudio) => ({
    type:types.setImagenesAudio,
    payload: imagenesAudio
})

export const setAudioConfig = (audioConfig) => ({
    type:types.setAudioConfig,
    payload: audioConfig
})

export const setIsActivoService = (isActivoService) => ({
    type:types.setIsActivoService,
    payload: isActivoService
})

export const setNewServiceCopy = (newServiceCopy) => ({
    type:types.setNewServiceCopy,
    payload: newServiceCopy
});

export const setImagenesPreloader = (imagenesPreloader) => ({
    type:types.setImagenesPreloader,
    payload: imagenesPreloader
});

export const setActiveLogged = (activeLogged) => ({
    type:types.setActiveLogged,
    payload: activeLogged
});

export const setActiveGeolocalizacion = (activeGeolocalizacion) => ({
    type:types.setActiveGeolocalizacion,
    payload: activeGeolocalizacion
});

export const setActiveServicesAll = (activeServicesAll) => ({
    type:types.setActiveServicesAll,
    payload: activeServicesAll
});