import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;
const imageUrl = process.env.REACT_APP_IMAGE_URL;

export const api = () => {
    const headers = {
        'Content-Type': 'application/json'
    }

    const defaultOptions = {
        baseURL,
        headers
    }

    const apiInstance = axios.create(defaultOptions);

    return apiInstance;
}

export const imageBaseUrl = imageUrl;