import { useState } from "react";
import { api } from "../api/apiConfig";
import { useAppState } from "./useAppState";
import { useService } from "./useService";

export const useCategory = () => {
    const {
    categoryId,
    setCategoryId,
    setSubcategoryId,
    setSubCategories,
    subCategories
    } = useAppState();

    const { getServicesBySubcategory } = useService();

  const [categories, setCategories] = useState([]);
  const [categoryOption, setCategoryOption] = useState(null);
  const [subcategoryOption, setSubcategoryOption] = useState(null);

  const format = (data) => {
    return data.map((e) => ({
      value: e.id,
      label: e.nombre,
      fatherId: e.idPadre,
    }));
  };

  const getCategories = (callback = false) => {
    api()
      .get("Categorias/PorTipo/S")
      .then((resp) => {
        resp.data.length > 0 && setCategories(format(resp.data));
        if(callback){
          callback(resp.data);
        }
      })
      .catch((error) => console.error(error));
  };
  
  

  const getSubcategories = () => {
    // Crear un arreglo de promesas para las solicitudes de tipo 'S' y 'R'
    const promises = [
        api().get('SubCategorias/PorTipo/S'),
        api().get('SubCategorias/PorTipo/R')
    ];
    Promise.all(promises)
        .then(results => {
            // results es un arreglo con las respuestas de cada promesa en el orden en que fueron agregadas
            const allData = results.reduce((acc, resp) => {
                // Asumiendo que cada respuesta tiene una propiedad data que es un arreglo
                if (resp.data.length > 0) {
                    acc = acc.concat(format(resp.data)); // Combina y formatea los datos
                }
                return acc;
            }, []); // Inicia con un arreglo vacío

            if (allData.length > 0) {
                setSubCategories(allData); // Actualiza el estado con todos los datos combinados
            }
        })
        .catch(error => {
            console.error(error); // Maneja el caso de error
        });
};

  const clearSubcategorySelection = () => {
    setSubcategoryOption(null);
    setSubcategoryId(0);
  };

  const onChangeCategory = (option) => {
    setCategoryOption(option);
    let newCategoryId = option !== null ? option.value : 0;
    if (categoryId !== newCategoryId) {
        clearSubcategorySelection();
        setCategoryId(newCategoryId);
    }
  };

  const onChangeSubcategory = (option) => {
    setSubcategoryOption(option);
    let newSubcategory = option !== null ? option.value : 0;
    if (newSubcategory > 0) {
        setSubcategoryId(option.value);
    }
  };

  

  return {
      categories,
      setCategories,
    getCategories,
    getSubcategories,
    onChangeSubcategory,
    subcategoryOption,
    onChangeCategory,
    categoryOption,
    setCategoryOption,
    clearSubcategorySelection,
  };
};
