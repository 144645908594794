import { useEffect } from "react";
import { useAppState } from "./useAppState";
import curso from '../components/panel/content/courses/audioCourse/courses_audio.json';

const useAudioPreloading = () => {
    const { setAudioConfig } = useAppState();

  useEffect(() => {
    const audioInstances = {};
  
    curso.forEach(curso => {
      curso.Capitulos.forEach(capitulo => {
        const audio = new Audio(capitulo.audio);
        audioInstances[`${curso.id}_${capitulo.id}`] = audio;
      });
    });
  
    setAudioConfig(audioInstances);
  
    return () => {
      Object.values(audioInstances).forEach(audio => audio.remove());
    };
  }, []);

  };
  
  export default useAudioPreloading;