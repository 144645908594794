import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AppRoutes } from './routes/AppRoutes';
import { AppProvider } from './context/AppProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import muitheme from './assets/data/MuiCustomTheme.json';

const theme = createTheme(muitheme);

export const App = () => {

    return (
        <>
            <AppProvider>
                <ThemeProvider theme={theme}>
                    <Router>
                        <AppRoutes />
                    </Router>
                </ThemeProvider>
            </AppProvider>
        </>
    )
}
