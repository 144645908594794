import { Suspense, useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ComponentsPage } from "../pages/libs/ComponentsPage";
import { routes } from "./routes";
import useImagePreloading from "../hooks/useImagenesPreLoader";
import useAudioPreloading from "../hooks/useAudiosPreLoader";
import { useUbigeo } from "../hooks/useUbigeo";
import { useAppState } from "../hooks/useAppState";
import { useCompany } from "../hooks/useCompany";
import { useUser } from "../hooks/useUser";
import { useService } from "../hooks/useService";
import { useCategory } from "../hooks/useCategory";
import Loader from "../components/common/Loader";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

export const AppRoutes = () => {

  const {
    loggedUser,
    departments,
    districtsAll,
    servicesAll,
    userServices,
    services,
    activeLogged,
    setActiveLogged,
    districts,
    setModifiedUser,
    modifiedUser,
    activeGeolocalizacion,
    setActiveGeolocalizacion
  } = useAppState();

  const { getCompanies } = useCompany();
  const {getDepartments} = useUbigeo();
  const {getUser} =useUser();
  const {getServicesbyUser,getServicesAll} = useService();
  const {getSubcategories,getCategories} = useCategory();
  
  // Cargamos las imagenes
  useImagePreloading();
  // Cargamos los Audios
  useAudioPreloading();

  const [loading,setLoading] = useState(false);
  useEffect(()=>{
    (departments!=undefined || departments.length == 0) && getDepartments(true);
  },[loggedUser]);

  useEffect(()=>{
    if(departments.length > 0 && Object.keys(districtsAll).length > 0){
      if(activeLogged == false){
        getCategories();
        getSubcategories();
        getServicesAll(true);
        if(loggedUser && loggedUser != undefined){
          getCompanies();
          getServicesbyUser(loggedUser.id, true);
          getServicesbyUser(loggedUser.id);
          getUser(loggedUser.id);
        }
        setActiveLogged(true);
        setLoading(true);
      }
    }
  },[districtsAll,departments,userServices,loggedUser]);

  return (
    <>
      {
        !loading ? (<><Loader /></>) : (
          <Suspense fallback={<><Loader /></>}>
            <Router>
              <div className="app-container">
                <div className="app-content">
                  <Switch>
                    {routes.map(({ path, component: Component, exact }) => {
                      return (
                        <Route
                          key={path}
                          path={path}
                          render={() => <Component />}
                          exact={exact === undefined ? true : exact}
                        />
                      );
                    })}
                    <Route path={"/components"} render={() => <ComponentsPage />} />
                    <Redirect to={routes[0].path} />
                  </Switch>
                </div>
              </div>
            </Router>
          </Suspense> 
        )
      }
    </>
  );
};
