import { useEffect, useState } from "react";
import { api } from "../api/apiConfig";
import { getCurrentDate } from "../utils/utils";
import { useAppState } from "./useAppState";
import { useService } from "./useService";
import { useUbigeo } from "./useUbigeo";
import { useUser } from "./useUser";

const nameBucket = process.env.REACT_APP_BUCKET_NAME;

export const usePublishService = () => {
    const {
        newService,
        company,
        setNewService,
        setCompany,
        serviceImages,
        setModalLoading,
        setPublishServiceModalOpen,
        setServiceImages,
        services,
        setServices,
        loggedUser,
        serviceSelected,
        setUserServices,
        userServices,
        setPublishServiceType,
        publishServiceType,
        modifiedUser,
        setModifiedUser,
        setResena,
        resena,
        setImageReseAll,
        newServiceResena,
        setNewServiceResena,
        setDetailServiceExtended,
        handleServiceDetailVisibility,
        setServiceSelected,
        setServiceSelectedAux,
        setIsImageAvaible,
        subCategories,
        serviceSelectedAux,
        servicesAll,
        setServicesAll
    } = useAppState();

    const { getServicesbyUser, getServices, structureServiceByIdUser,getServicesAll } = useService();
    const {getUser} =useUser();
    const {getDepartments} = useUbigeo();
    const [compRespPost, setCompRespPost] = useState(null);
    const [imgRespPost, setImgRespPost] = useState(null);


    const postService = (isNewServiceResena = false) => {
        if (!isNewServiceResena) {
            const isNewService = !newService.id;
            let newsubCategoryId='';
            subCategories.forEach((subcategory) => {
                if (subcategory.label === newService.category.label) {
                    newsubCategoryId=subcategory.value;
                }
                if(subcategory.label === newService.category.label){
                    newsubCategoryId=subcategory.value;
                }
            });
            const body = {
                "servicio": {
                    servInterno: isNewService ? 0 : newService.id,
                    servDireccion: newService.address,
                    servDescripcion: newService.description,
                    servTieneDelivery: newService.hasDelivery.value,
                    servActivo: true,
                    servLatitud: newService.latitude,
                    servLongitud: newService.longitude,
                    ubigInterno: newService?.district?.value,
                    usuaInterno: loggedUser.id,
                    subcInterno: newsubCategoryId,
                    servHorario: serviceSelectedAux?.schedule?.value === "L" ? null : serviceSelectedAux?.horaAten,
                    servNombre: newService.nombre
                }
            }

            api()
                .post("Servicios/Guardar", body)
                .then((response) => {
                    setTimeout(() => {
                        getServices();
                        getServicesAll(true);
                        postImagesService(response.data);
                        setPublishServiceModalOpen(true);
                        setModalLoading(false);
                        setNewService({
                            ...newService,
                            id: response.data.id,
                            servMontoCulqi: null
                        });
                        getServicesbyUser(loggedUser.id);
                        getServicesbyUser(loggedUser.id,true);
                        getDepartments(true);
                        getUser(loggedUser?.id);
                        // const serviceAllActual=servicesAll.find((service)=>service?.id == response.data.id);
                        // if(servicesAll.some((service)=>service?.id == response.data.id)){
                        //     setServicesAll({...servicesAll,})
                        // }
                        // setServicesAll(servicesAll)
                    }, 500);
                });
        } else {
            const post = (body) => {
                api().post("Servicios/Guardar", body)
                    .then((response) => {
                        postImagesResenas(response.data);
                        if (!isNewServiceResena) postImagesService(response.data);
                        getServices();
                        getServicesAll(true);
                        getServicesbyUser(loggedUser.id);
                        setDetailServiceExtended(false);
                        handleServiceDetailVisibility();
                    });
            };
            if (newServiceResena.isExist) {
                const body = {
                    servicio: {
                        servInterno: newServiceResena.id,
                        servDireccion: newServiceResena.direccion,
                        servDescripcion: newServiceResena.descripcion,
                        servActivo: true,
                        servLatitud: newServiceResena.latitud,
                        servLongitud: newServiceResena.longitud,
                        ubigInterno: newServiceResena.idUbigeo,
                        usuaInterno: newServiceResena.idUsuario,
                        subcInterno: newServiceResena.idCategoria,
                        servHorario: newServiceResena.horario,
                        servTieneDelivery: newServiceResena.tieneDelivery,
                        servNombre: newServiceResena.nombreServicio
                    },
                    resena: {
                        reseInterno: newServiceResena.reseInterno,
                        reseValor: newServiceResena.resena,
                        reseCalificacion: newServiceResena.calificaciones == undefined  ? 1 : parseInt(newServiceResena.calificaciones, 10),
                        usuaInterno: loggedUser.id
                    }
                };
                post(body);
            } else {
                const body = {
                    servicio: {
                        servInterno: 0,
                        servLatitud: newService.latitude,
                        servLongitud: newService.longitude,
                        ubigInterno: newService.ubigInterno,
                        usuaInterno: loggedUser.id,
                        subcInterno: 83,
                        servNombre: newServiceResena.nombreServicio
                    },
                    resena: {
                        reseValor: newServiceResena.resena,
                        reseCalificacion: newServiceResena.calificaciones == undefined  ? 1 : parseInt(newServiceResena.calificaciones, 10),
                        usuaInterno: loggedUser.id
                    }
                };
                post(body);
            }
        }
    };



    const postCompany = () => {
        if (Object.keys(company).length !== 0) {
            let body = {
                id: company?.id,
                cargo: null,
                direccion: company?.direccion,
                latitud: company?.latitud == undefined ? 0 : company?.latitud,
                longitud: company?.longitud == undefined ? 0 : company?.longitud,
                idUbigeo: company?.idUbigeo,
                empresa: company?.empresa,
                ruc: company?.ruc?.toString(),
                idUsuario: company?.idUsuario,
            };
            api()
                .post("Empresas/Guardar", body)
                .then((response) => {
                    setCompany({ ...company, id: response.data.id });
                    setCompRespPost(response.data);
                    setPublishServiceType('business');
                    postImagesCompany(response.data);
                }).catch((error) => {
                    setCompany({});
                    setPublishServiceType('independent');
                });

        }
    };

    const postOneImage = (image, id, type, table) => {
        if (image !== undefined) {
            const formData = new FormData();
            formData.append("formFile", image?.formFile);
            formData.append("id", id);
            formData.append("tipo", type);
            formData.append("tabla", table);
            formData.append("interno", 0);
            
            api()
                .post(`Archivos/SubirArchivo?nameBucket=${nameBucket}`, formData)
                .then((response) => {
                    setImgRespPost(response.data);
                    if(table == "Servicios"){
                        setIsImageAvaible(true);
                    }
                });
        }
    };

    const postImagesCompany = (responseCompany) => {
        if (serviceImages !== null) {
            postOneImage(serviceImages.logImg, responseCompany.id, "LOG", "Empresas");
            postOneImage(serviceImages.ptdImg, responseCompany.id, "PTD", "Empresas");
            setServiceImages({});
        }
    }

    const postImagesIndependent = () => {
        if (serviceImages !== null) {
            postOneImage(serviceImages.logImg, modifiedUser.id, "LOG", "Usuarios");
            postOneImage(serviceImages.ptdImg, modifiedUser.id, "PTD", "Usuarios");
            setServiceImages({});
        }
    }

    const postImagesService = (responseService) => {
        if (serviceImages !== null) {
            postOneImage(
                serviceImages.srvImg1,
                responseService.id,
                "SRV",
                "Servicios"
            );
            postOneImage(
                serviceImages.srvImg2,
                responseService.id,
                "SRV",
                "Servicios"
            );
            postOneImage(
                serviceImages.srvImg3,
                responseService.id,
                "SRV",
                "Servicios"
            );
            setServiceImages({});
            
        }
    }

    const postImagesResenas = (responseService) => {
        postOneImage(
            serviceImages.resImg1,
            responseService.idResena,
            "RES",
            "Resenas"
        );
        postOneImage(
            serviceImages.resImg2,
            responseService.idResena,
            "RES",
            "Resenas"
        );
        postOneImage(
            serviceImages.resImg3,
            responseService.idResena,
            "RES",
            "Resenas"
        );
    }

    const getArchiveByService = (id, callback = null) => {
        api()
            .get("/Archivos/Por_Tabla_Codigo_Tipo/Servicios/" + id + "/SRV")
            .then((resp) => {
                if (callback) {
                    callback(resp.data);
                } else {
                    const archInternos = resp.data.map(objeto => objeto.archInterno.toString()).join(', ');
                    setNewService({
                        ...newService,
                        fotoServicios: archInternos,
                        imagenesServicios: resp.data
                    });
                }
            })
            .catch((error) => console.error(error));
    }
    

    const getArchiveByCompany = (id, type) => {
        Promise.all([
            api().get("/Archivos/Por_Tabla_Codigo_Tipo/" + type + "/" + id + "/LOG")
                .catch(error => {
                    console.error('Error en la solicitud LOG:', error);
                    return { data: [] };
                }),
            api().get("/Archivos/Por_Tabla_Codigo_Tipo/" + type + "/" + id + "/PTD")
                .catch(error => {
                    console.error('Error en la solicitud PTD:', error);
                    return { data: [] };
                })
        ])
            .then(([respLOG, respPTD]) => {
                if (publishServiceType !== "independent") {
                    setCompany({
                        ...company,
                        imagenesCompany: [...respLOG.data, ...respPTD.data],
                    });
                } else {
                    setModifiedUser({
                        ...modifiedUser,
                        imagenIdependent: [...respLOG.data, ...respPTD.data]
                    })
                }

            })
            .catch((errors) => {
                console.error('Errores generales:', errors);
            });
    };
    

    const getArchiveByResena = async (reseIds, callback) => {
        try {
            const resePromises = reseIds.map((reseId) =>
                api().get("/Archivos/Por_Tabla_Codigo_Tipo/Resenas/" + reseId + "/RES")
            );

            const reseResponses = await Promise.all(resePromises);

            const reseImage = reseResponses
                .filter((resp) => resp.data !== null)
                .map((resp) => resp.data);

            if (callback && typeof callback === 'function') {
                callback(null, reseImage);
            }

            return reseImage;
        } catch (error) {
            console.error(error);
            if (callback && typeof callback === 'function') {
                callback(error, null);
            }
            throw error;
        }
    };



    const deleteImage = (id) => {
        const data = {
            ArchInterno: parseInt(id)
        }

        api()
            .post("Archivos/DeleteArchivo", data)
            .then((response) => {
                console.log(response.data);
            });
    }

    return {
        postService,
        compRespPost,
        postCompany,
        //postImages,
        imgRespPost,
        deleteImage,
        getArchiveByService,
        getArchiveByCompany,
        postImagesIndependent,
        getArchiveByResena
    };
};
