import { getDistance } from "geolib";

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatPrice = (value, decimals = 2, symbol = "S/.") => {
  if (typeof value === "string") return value;
  if (!isNaN(value))
    return `${symbol} ${Number(
      Math.round(Number(value + "e" + decimals)) + "e-" + decimals
    ).toFixed(decimals)}`;
  return formatPrice(0, decimals, symbol);
};

export const AllowOnlyNumbers = (value, maxLength = 1000) => {
  return value
    .toString()
    .replace(/[^0-9]/g, "")
    .slice(0, maxLength);
};

export const clearDepartmentName = (value) => {
  return value
    .slice(0, value.indexOf("("))
    .trim()
    .toUpperCase();
};

export const encryptB64 = (str) => {
  return encodeURIComponent(btoa(str));
};

export const decryptB64 = (str) => {
  let result = "0";
  try {
    result = atob(decodeURIComponent(str));
  } catch (error) {
    result = "0";
  }
  return Number(result);
};

export const getCurrentDate = () => {
  const today = new Date();
  const day = today.toLocaleDateString().split("/")[0];
  const month = today.toLocaleDateString().split("/")[1];
  const year = today.toLocaleDateString().split("/")[2];
  return `${year}-${month.length === 2 ? month : `0${month}`}-${
    day.length === 2 ? day : `0${day}`
  }`;
};

export const fixDateRead = (date) => {
  let year = date.$y.toString();
  let month = (date.$M + 1).toString();
  let day = date.$D.toString();

  return `${year}-${month.length === 2 ? month : `0${month}`}-${
    day.length === 2 ? day : `0${day}`
  }`;
};

export const getDistanceFromService = (
  currentLocation,
  latitudeService,
  longitudeService
) => {
  let d = "";
  if (
    currentLocation !== undefined &&
    latitudeService !== undefined &&
    longitudeService !== undefined
  ) {
    d = getDistance(currentLocation, {
      latitude: latitudeService,
      longitude: longitudeService,
    });
    d = `${Math.round(d / 1000)} km`;
  }
  return d;
};

export const getDistanceMetrosFromService = (
  currentLocation,
  latitudeService,
  longitudeService
) => {
  let d = "";
  if (
    currentLocation !== undefined &&
    latitudeService !== undefined &&
    longitudeService !== undefined
  ) {
    d = getDistance(currentLocation, {
      latitude: latitudeService,
      longitude: longitudeService,
    });
    d = `${Math.round(d)} m`; // Cambiar la unidad de km a m
  }
  return d;
};

export const calculateDistanceBetweenCoordinates = (
  originLatitude,
  originLongitude,
  destinationLatitude,
  destinationLongitude
) => {
  const R = 6371; // radio de la tierra en km
  const dLat = ((destinationLatitude - originLatitude) * Math.PI) / 180;
  const dLon = ((destinationLongitude - originLongitude) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((originLatitude * Math.PI) / 180) *
      Math.cos((destinationLatitude * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;
  return distance; // distancia en km
};

export const getRedirectURL = (idDepartmnet, idService) => {
  return `/servicios?m=map&d=${encryptB64(idDepartmnet)}&s=${encryptB64(
    idService
  )}`;
};

export const formatUbigeous = (data, mustShowQuantity) => {
  return data.map((e) => ({
    value: e.id,
    label: mustShowQuantity ? e.nombre2 : e.nombre,
    quantity: e.cantidad,
  }));
};

export const times = [
  {
    value: "00:00",
    label: "00:00",
  },
  {
    value: "01:00",
    label: "01:00",
  },
  {
    value: "02:00",
    label: "02:00",
  },
  {
    value: "03:00",
    label: "03:00",
  },
  {
    value: "04:00",
    label: "04:00",
  },
  {
    value: "05:00",
    label: "05:00",
  },
  {
    value: "06:00",
    label: "06:00",
  },
  {
    value: "07:00",
    label: "07:00",
  },
  {
    value: "08:00",
    label: "08:00",
  },
  {
    value: "09:00",
    label: "09:00",
  },
  {
    value: "10:00",
    label: "10:00",
  },
  {
    value: "11:00",
    label: "11:00",
  },
  {
    value: "12:00",
    label: "12:00",
  },
  {
    value: "13:00",
    label: "13:00",
  },
  {
    value: "14:00",
    label: "14:00",
  },
  {
    value: "15:00",
    label: "15:00",
  },
  {
    value: "16:00",
    label: "16:00",
  },
  {
    value: "17:00",
    label: "17:00",
  },
  {
    value: "18:00",
    label: "18:00",
  },
  {
    value: "19:00",
    label: "19:00",
  },
  {
    value: "20:00",
    label: "20:00",
  },
  {
    value: "21:00",
    label: "21:00",
  },
  {
    value: "22:00",
    label: "22:00",
  },
  {
    value: "23:00",
    label: "23:00",
  },
];
