export const types = {
    setCategoryId: "setCategoryId",
    setCollapsed: "setCollapsed",
    setConfirmModalOpen: "setConfirmModalOpen",
    setModalRegisterCodeOpen: "setModalRegisterCodeOpen",
    setModalRecoverPasswordOpen: "setModalRecoverPasswordOpen",
    setModalOkOpen: "setModalOkOpen",
    setCurrentLatLng: "setCurrentLatLng",
    setDepartmentClassName: "setDepartmentClassName",
    setDepartmentId: "setDepartmentId",
    setDepartmentName: "setDepartmentName",
    setDepartments: "setDepartments",
    setDistricts: "sedDistricts",
    setDetailServiceExtended: "setDetailServiceExtended",
    setDetailServiceOpened: "setDetailServiceOpened",
    setMode: "setMode",
    setPanelSideBarCollapsed: "setPanelSideBarCollapsed",
    setPublishServiceModalOpen: "setPublishServiceModalOpen",
    setPublishServiceStep: "setPublishServiceStep",
    setPublishServiceType: "setPublishServiceType",
    setSearchServiceText: "setSearchServiceText",
    setServices: "setServices",
    setUserServices: "setUserServices",
    setServiceSelected: "setServiceSelected",
    setShareOpened: "setShareOpened",
    setSubcategoryId: "setSubcategoryId",
    setSubCategories: "setSubCategories",
    setDistrictName: "setDistrictName",
    setLoggedUser: "setLoggedUser",
    setModifiedUser: "setModifiedUser",
    setCompany: "setCompany",
    setServiceImages: "setServiceImages",
    setModalLoading: "setModalLoading",
    signIn: "signIn",
    signOut: "signOut",
    setDistrictsAll: 'setDistrictsAll',
    setModalRegisterComent: "setModalRegisterComent",
    setModalOkResena: "setModalOkResena",
    setResena: "setResena",
    setUsersAll: "setUsersAll",
    "setModalInicioSesion":'setModalInicioSesion',
    "setImageReseAll":"setImageReseAll",
    "setNewServiceResena": "setNewServiceResena",
    "setServiceSelectedAux": "setServiceSelectedAux",
    "setModalListResena": "setModalListResena",
    "setServiceList":"setServiceList",
    "setIsImageAvaible":"setIsImageAvaible",
    "setServicesAll":"setServicesAll",
    setImagenesAudio:"setImagenesAudio",
    setAudioConfig:"setAudioConfig",
    "setIsActivoService":"setIsActivoService",
    "setNewServiceCopy":"setNewServiceCopy",
    "setImagenesPreloader":"setImagenesPreloader",
    "setActiveLogged":"setActiveLogged",
    "setActiveGeolocalizacion":"setActiveGeolocalizacion",
    "setActiveServicesAll":"setActiveServicesAll",
};
