import { lazy } from 'react';

const Home = lazy( () => import(/* webpackChunkName: "Home" */  '../pages/home/HomePage' ) );
const Login = lazy( () => import(/* webpackChunkName: "Login" */  '../pages/auth/LoginPage' ) );
const Register = lazy( () => import(/* webpackChunkName: "Register" */  '../pages/auth/RegisterPage' ) );
const Forgot = lazy( () => import(/* webpackChunkName: "Forgot" */  '../pages/auth/ForgotPage' ) );
const Services = lazy( () => import(/* webpackChunkName: "Services" */  '../pages/services/ServicesPage' ) );
const Panel = lazy(() => import(/* webpackChunkName: "Panel" */  '../pages/panel/PanelPage'));
const TermsAndConditions = lazy(() => import(/* webpackChunkName: "TermsAndConditions" */  '../pages/auth/TermsAndConditionsPage'));

export const routes = [
    {
        path: '/',
        component: Home,
    },
    {
        path: '/servicios',
        component: Services,
    },
    {
        path: '/iniciar',
        component: Login,
    },
    {
        path: '/registro',
        component: Register,
    },
    {
        path: '/recuperar',
        component: Forgot,
    },
    {
        path: '/panel',
        component: Panel,
    },
    {
        path: '/panel/:option',
        component: Panel,
        exact: false,
    },
    {
        path: '/terminos-condiciones-de-uso',
        component: TermsAndConditions,
    }
];