import { api } from '../api/apiConfig';
import { useAppState } from './useAppState';
import positions from '../assets/data/Positions.json';

export const useCompany = () => {

    const { loggedUser, setCompany, company } = useAppState()

    const getCompanies = (ciudad, distrito) => {
        if (loggedUser) {
            api().get('Empresas/PorUsuario/' + loggedUser.id)
                .then(resp => {
                    if (ciudad) {
                        setCompany({
                            ...company,
                            direccion: ciudad + ", " + distrito?.label,
                            city: ciudad,
                            district: distrito,
                            cargo: getCargo(company.cargo?.label),
                            idUbigeo: distrito?.value
                        });
                    } else {
                        setCompany({
                            ...resp.data,
                            cargo: getCargo(resp.data.cargo)
                        });
                    }
                })
                .catch(error => console.error(error));
        }
    };


    const getCompanyParamsRed = () => {

        if (Object.keys(company).length !== 0) {
            setCompany(
                {
                    id: company.id,
                    cargo: company.cargo,
                    direccion: company.direccion,
                    latitud: company.lat,
                    longitud: company.lng,
                    idUbigeo: company.idUbigeo,
                    empresa: company.empresa,
                    ruc: company.ruc,
                    idUsuario: company.idUsuario
                })
        }
    }

    const getCargo = (value) => {
        return positions.find(x => x.label === value) || positions[0];
    };

    return {
        getCompanies,
        getCompanyParamsRed
    }
}