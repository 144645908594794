import { useEffect, useState } from "react";
import { api } from "../api/apiConfig";
import { useAppState } from "./useAppState";
//import { setModifiedUser } from "../context/actions/Actions";

export const useUser = () => {
  const {
    modifiedUser,
    loggedUser,
    setLoggedUser,
      setModalOkOpen,
      setModifiedUser,
      resena,
      setUsersAll,
      newService,
      serviceSelected,
      userServices,
      company,
      districtsAll

  } = useAppState();
  const {
    setModalRegisterCodeOpen,
    setModalRecoverPasswordOpen,
  } = useAppState();

  const [userRespPost, setUserRespPost] = useState(null);
  const [phoneExist, setPhoneExist] = useState(false);
  const [emailExist, setEmailExist] = useState(false);
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getUser = (userId, callback = null) => {
    api()
      .get("Usuarios/ObtenerPorId/" + userId)
      .then((resp) => {
          resp.data !== null && setUser(resp.data);
          setLoggedUser(resp.data);
          setModifiedUser(resp.data);
          if (callback && typeof callback === 'function') {
              callback(resp.data);
          }
      })
      .catch((error) => console.error(error));
};


    const getUsersNames = async (userIds) => {
        try {
            const userPromises = userIds.map((userId) =>
                api().get("Usuarios/ObtenerPorId/" + userId)
            );

            const userResponses = await Promise.all(userPromises);

            const usersData = userResponses
                .filter((resp) => resp.data !== null)
                .map((resp) => resp.data);

            return usersData;
        } catch (error) {
            console.error(error);
            throw error;
        }
    };


  const getUserParamsRed = () => {
    if (Object.keys(loggedUser).length !== 0) {
      setUser({
        id: loggedUser.id,
        nombres: loggedUser.nombres,
        apellidos: loggedUser.apellidos,
        sexo: loggedUser.sexo,
        fecNacimiento: loggedUser.fecNacimiento,
        contacto: loggedUser.contacto,
        correo: loggedUser.correo,
        dni: loggedUser.dni,
        pais: loggedUser.pais,
        ciudad: loggedUser.ciudad,
        idUbigeo: loggedUser.idUbigeo,
        tieneEmpresa: loggedUser.tieneEmpresa,
      });
    }
  };

  const postUser = (hideModal) => {
      if (Object.keys(modifiedUser).length !== 0) {
      api()
        .post("usuarios/guardar", modifiedUser)
          .then((response) => {
              setModifiedUser(response.data);
          setUserRespPost(response.data);
          setLoggedUser({
            ...loggedUser,
            nombres: modifiedUser.nombres,
            apellidos: modifiedUser.apellidos,
            dni: modifiedUser.dni,
            contacto: modifiedUser.contacto,
            correo: modifiedUser.correo,
          });
            localStorage.setItem("user", JSON.stringify(response.data));
          // if (!hideModal) setModalOkOpen(true);
        });
    }
  };

  // [HttpGet("EnviarCodigoCorreoExistente/{x_correo}/{x_codigo}")]
  const sendCodeRecoverByEmail = (correo, codigo) => {
    api()
      .get("usuarios/EnviarCodigoCorreoExistente/" + correo + "/" + codigo)
      .then((resp) => {
        let rpta = resp.data.estado;
        if (rpta == "correcto") {
          setModalRecoverPasswordOpen(true);
          setIsLoading(false);
          console.log("Enviado");
        } else {
          console.log("Email No existe");
        }
      })
      .catch((error) => console.error(error));
  };

  // [Route("ActualizarPassword/{x_esTelefono}/{x_valor}/{x_password}")]

  const ActualizarPassword = (esTelefono, valor, password, callback) => {
    api()
      .get(
        "usuarios/ActualizarPassword/" +
        esTelefono +
          "/" +
          valor +
          "/" +
          password
      )
        .then((resp) => {
        callback(resp.data);
      })
      .catch((error) => console.error(error));
  };

  // [HttpGet("EnviarCodigoCorreo/{x_correo}/{x_codigo}")]
  const sendCodeByEmail = (correo, codigo) => {
    api()
      .get("usuarios/EnviarCodigoCorreoSiNoExiste/" + correo + "/" + codigo)
      .then((resp) => {
        let rpta = resp.data.estado;
        if (rpta == "correcto") {
          setModalRegisterCodeOpen(true);
          setIsLoading(false);
        } else {
          setEmailExist(true);
        }
      })
      .catch((error) => console.error(error));
  };

  // [Route("ExisteTelefono/{x_telefono}")]
  const ExisteTelefono = (telefono) => {
    api()
      .get("usuarios/ExisteTelefono/" + telefono)
      .then((resp) => {
        console.log(resp.data);
      })
      .catch((error) => console.error(error));
  };

  //Validacion si Correo y Email existen
  const ValidatesEmailAndPhone = (code, email, phone) => {
    api()
      .get("usuarios/ExisteTelefono/" + phone)
      .then((resp) => {
        if (!resp.data) {
          setIsLoading(true);
          setPhoneExist(false);
          sendCodeByEmail(email, code);
        } else {
          setPhoneExist(true);
        }
      })
      .catch((error) => console.error(error));
  };

  return {
    getUser,
    postUser,
    getUserParamsRed,
    userRespPost,
    sendCodeByEmail,
    sendCodeRecoverByEmail,
    ActualizarPassword,
    ExisteTelefono,
    ValidatesEmailAndPhone,
    phoneExist,
    emailExist,
    setPhoneExist,
    setEmailExist,
    isLoading,
    setIsLoading,
    //sendCodeByPhone,
    user,
    setUser,
    getUsersNames
  };
};
