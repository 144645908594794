import { useState, useRef, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppState } from "./useAppState";
import { api } from "../api/apiConfig";
import { clearDepartmentName, decryptB64, encryptB64 } from "../utils/utils";
import { getGeocode } from "use-places-autocomplete";
import { formatUbigeous } from "../utils/utils";

export const useUbigeo = () => {
    const history = useHistory();
    const location = useLocation();
    const defaultDepartmentId = 1392;
    const initialized = useRef(false);
    const serviceIdParam = useRef(0);
    const modeParam = useRef("map");
    const {
        departments,
        departmentId,
        setDepartments,
        setDistricts,
        districts,
        setDepartmentId,
        setDepartmentName,
        setDepartmentClassName,
        setMode,
        handleServiceDetailVisibility,
        setCategoryId,
        setSubcategoryId,
        setDistrictsAll,
        districtsAll,
        setCompany,
        setNewService,
        company,
        newService,
        setModifiedUser,
        modifiedUser
    } = useAppState();

    const [departmentOption, setDepartmentOption] = useState(null);
    const [departmentAll, setDepartmentAll] = useState(null);

    const getDepartments = async (activeDistrictsAll = false) => {
        try {
            const resp = await api().get("Ubigeos/ObtenerDepartamentos/1");
            resp.data.length > 0 && setDepartments(resp.data);
            resp.data.length > 0 && setDepartmentAll(resp.data);
            var tempDepartment = resp.data.find((d) => d.id === defaultDepartmentId);
            setDepartmentOption({
                value: tempDepartment.id,
                label: tempDepartment.nombre,
                quantity: 0,
            });
    
            if (activeDistrictsAll === true) {

                if (resp.data.length > 0) {
                    const promises = resp.data.map((department) => getDistrictsAsync(department.id));
                    const districtArrays = await Promise.all(promises);
                    const allDistricts = districtArrays.flat(); 
                    setDistrictsAll(allDistricts);
                    updateDistrictName(1433, "SANTIAGO DE SURCO");
                    // if(company?.id != undefined && company?.id > 0){ //Si existe Company
                    //     var districtTemp = allDistricts.find((d) => d.id === company?.idUbigeo);
                    //     const departmentTemp = resp.data.find((d) => d.id === districtTemp?.idPadre); 
                    //     // if (departmentTemp != undefined || departmentTemp != null) {
                    //     //     getDistricts(departmentTemp.id,null,null,((districts_)=>{
                    //     //         const districtFind = districts_.find(district => district.id === company?.idUbigeo);
                    //     //         setCompany({
                    //     //             ...company,
                    //     //             distrito:{
                    //     //                 label: districtFind?.nombre,
                    //     //                 quantity: 0,
                    //     //                 value: districtFind?.id,
                    //     //             }
                    //     //         })
                    //     //     }),false);
                    //     // }
                    // }
                }
            }
        } catch (error) {
            console.error("Error fetching departments:", error);
        }
    };
    

    const onChangeDepartment = (option, skipPush = true) => {
        setDepartmentOption(option);
        setDepartmentId(option !== null ? option.value : 0);
        setDepartmentName(option !== null ? clearDepartmentName(option.label) : "");
        setCategoryId(null);
        setSubcategoryId(null);
        option === null && handleClassNameDepartment();
        !skipPush &&
            history.push({
                pathname: "servicios",
                search: `?d=${encryptB64(option !== null ? option.value : 0)}`,
            });
    };

    const onChangeDepartmentModal = (option) => {
        getDistricts(option?.value,null,null,(district)=>{
            const address = option?.label + ", " + formatUbigeous(district)[0]?.label;
            getGeocode({ address: address }).then((results) => {
                const { lat, lng } = results[0].geometry.location;
                setCompany({
                    ...company,
                    latitud: lat(),
                    longitud: lng(),
                    district: formatUbigeous(district)[0]?.label,
                    idUbigeo: formatUbigeous(district)[0]?.value,
                    direccion: address,
                });
                setNewService({
                    ...newService,
                    latitude: lat(),
                    longitude: lng(),
                    district: {
                        label: formatUbigeous(district)[0]?.label,
                        quantity: 0,
                        value: formatUbigeous(district)[0]?.value,
                    },
                    idUbigeo: formatUbigeous(district)[0]?.value,
                    department: option,
                    address: address,
                    saveAddress: false,
                });
                setModifiedUser({
                    ...modifiedUser, 
                    idUbigeo:option?.value,
                    idUbigeoAux:option?.value,
                    department: option,
                    district: {
                        label: formatUbigeous(district)[0]?.label,
                        quantity: 0,
                        value: formatUbigeous(district)[0]?.value,
                    },
                    latitude: lat(),
                    longitude: lng(),
                    address: address,
                })
            });
        });
    };

    const handleClassNameDepartment = () => {
        setDepartmentClassName("animate__animated animate__shakeX");
        setTimeout(() => {
            setDepartmentClassName("animate__animated");
        }, 500);
    };

    const checkParams = () => {
        const params = new URLSearchParams(location.search);

        const m = params.get("m");
        const d = params.get("d");
        const s = params.get("s");

        let mode = m !== null ? String(m).toLowerCase() : "";
        let departmentId = d !== null ? decryptB64(d) : 0;
        let serviceId = s !== null ? decryptB64(s) : 0;

        mode =
            mode === "map" || mode === "list" || mode === "advert" ? mode : "map";
        departmentId = !isNaN(departmentId) ? departmentId : 0;
        serviceId = !isNaN(serviceId) ? serviceId : 0;

        //Establish mode
        modeParam.current = mode;

        //1. There are 2 parameters both correct
        if (d !== null && departmentId !== 0 && s !== null && serviceId !== 0) {
            loadServices(departmentId, serviceId);
        }
        //2. There are 2 parameters: one correct and the other incorrect, or both incorrect
        else if (d !== null && s !== null) {
            loadServices(
                departmentId !== 0 && serviceId === 0
                    ? departmentId //Department is correct and service is incorrect
                    : defaultDepartmentId //Service is correct and deparment is incorrect OR Both incorrect
            );
        }
        //3. There are 1 parameter and is correct
        else if (d !== null && departmentId !== 0) loadServices(departmentId);
        //The only parameters service is incorrect OR there are no parameters set
        else loadServices(defaultDepartmentId);
    };

    const loadServices = (departmentId, serviceId = 0) => {
        let search = `?m=${modeParam.current}&`;
        setMode(modeParam.current);

        if (departmentId !== 0) {
            let deparmentItem = departments.find((x) => x.id === departmentId);
            deparmentItem =
                deparmentItem !== undefined ? deparmentItem : departments[0];
            search += `d=${encryptB64(deparmentItem.id)}`;
            initialized.current = true;
            onChangeDepartment(
                {
                    value: deparmentItem.id,
                    label: deparmentItem.nombre2,
                    quantity: deparmentItem.cantidad,
                },
                true
            );
        }

        serviceIdParam.current = serviceId;
        if (serviceId !== 0) {
            search += `&s=${encryptB64(serviceId)}`;
            history.replace({
                pathname: "servicios",
                search,
            });
        } else {
            handleServiceDetailVisibility();
        }
    };

    const getDistricts = (idPadre, setDistrictsOption, idUbigeo, callback,isActive = false) => {
        api()
            .get("Ubigeos/DistritosPorDepartamento/" + idPadre)
            .then((resp) => {
                const nombresVistos = new Set();
                const arregloModificado = [];
                resp.data.forEach(objeto => {
                    if (!nombresVistos.has(objeto.nombre)) {
                        nombresVistos.add(objeto.nombre);
                        arregloModificado.push(objeto);
                    }
                });
                // setDistricts(arregloModificado);
                if(isActive == false){
                    setDistricts(resp.data);

                }
                if (callback) {
                    callback(arregloModificado);
                }
                if (setDistrictsOption != null) {
                    setDistrictsOption(null);
                    if (setDistrictsOption && idUbigeo) {
                        if (arregloModificado?.length > 0) {
                            let districtTemp = arregloModificado.find((d) => d.id === idUbigeo);
                            if (districtTemp) {
                                setDistrictsOption({
                                    label: districtTemp.nombre,
                                    quantity: 0,
                                    value: districtTemp.id,
                                });
                            }
                        }
                    }
                }
            })
            .catch((error) => console.error(error));
    };

    const getDistrictsAsync = (idDepartment) => {
        return new Promise((resolve) => {
            getDistricts(idDepartment, null, null, (newDistricts) => {
                resolve(newDistricts);
            },true);
        });
    };

    const updateDistrictName = (districtId, newName) => {
        if(Object.keys(districtsAll).length > 0){
            const updatedDistricts = districtsAll.map(district => {
                if (district.id === districtId) {
                    return { ...district, nombre: newName }; 
                }
                return district; 
            });
            setDistrictsAll(updatedDistricts); 
        }
    };

    return {
        checkParams,
        departmentId,
        setDepartmentOption,
        departmentOption,
        departments,
        getDepartments,
        getDistricts,
        handleClassNameDepartment,
        history,
        initialized,
        location,
        onChangeDepartment,
        onChangeDepartmentModal,
        serviceIdParam,
        // fetchAndSetDistricts
    };
};
