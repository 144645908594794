import { useReducer, useRef } from "react";
import { AppContext } from "./AppContext";
import { AppReducer } from "./AppReducer";
import {
    publishServiceStepsType,
    publishServiceType,
} from "../types/PublishServiceTypes";
import * as actions from "../context/actions/Actions";

const INITIAL_STATE = {
    loggedUser: null,
    categoryId: 0,
    collapsed: true,
    company: {},
    confirmModalOpen: false,
    currentLatLng: {
        latitude: -12.046365551588833,
        longitude: -77.0427643007452,
    },
    departmentClassName: "animate__animated",
    departmentId: 0,
    departmentName: "",
    departments: [],
    districts: [],
    districtId: 1394,
    districtName: "",
    isPanelSideBarCollapsed: true,
    isServiceDetailExtended: false,
    isServiceDetailOpened: false,
    isServiceShareOpened: false,
    logged: false,
    modalLoading: false,
    modalRegisterCodeOpen: false,
    modalRecoverPasswordOpen: false,
    modalOkOpen: false,
    mode: null,
    publishServiceModalOpen: false,
    publishServiceStep: publishServiceStepsType.typeSelection,
    publishServiceType: publishServiceType.independent,
    searchText: "",
    services: [],
    userServices: [],
    serviceImages: {},
    newService: {
        nombre: null,
        category: null,
        department: null,
        district: null,
        address: "",
        priceType: null,
        price: "",
        description: "",
        latitude: 0,
        longitude: 0,
        addressSaved: false,
        horaAten: "",
        horaAtenInicio: "",
        horaAtenFinal: "",
    },
    serviceSelected: null,
    subcategoryId: 0,
    subCategories: [],
    districtsAll: {},
    modalRegisterComent: false,
    modalOkResena: false,
    resena: [],
    usersAll: [],
    modalInicioSesion: false,
    imageReseAll: [],
    newServiceResena: {},
    serviceSelectedAux: null,
    modalListResena: false,
    serviceList: [],
    isImageAvaible: false,
    servicesAll: [],
    imagenesAudio:{},
    // audioConfig:{
    //     id: 0,
    //     chapterId:0,
    //     showContent:false,
    //     isPlaying:false,
    //     currentTime:0,
    //     duration:0
    // },
    audioConfig:null,
    isActivoService:false,
    newServiceCopy:undefined,
    imagenesPreloader:{},
    activeLogged:false,
    activeGeolocalizacion:false,
    activeServicesAll:false
};

export const AppProvider = ({ children }) => {
    /**********************/
    /*   AUTHENTICATION   */
    /**********************/

    const signIn = () => {
        dispatch(actions.signIn());
    };

    const signOut = () => {
        dispatch(actions.signOut());
    };

    /****************/
    /*   LOCATION   */
    /****************/

    const setCurrentLatLng = (LatLng) => {
        dispatch(actions.setCurrentLatLng(LatLng));
    };

    /**********************/
    /*     LOGGED USER    */
    /**********************/

    const setLoggedUser = (loggedUser) => {
        dispatch(actions.setLoggedUser(loggedUser));
    };

    /**********************/
    /*    MODIFIED USER   */
    /**********************/

    const setModifiedUser = (modifiedUser) => {
        dispatch(actions.setModifiedUser(modifiedUser));
    };

    /****************/
    /*   SERVICES   */
    /****************/

    const setServices = (services) => {
        dispatch(actions.setServices(services));
    };

    const setUserServices = (userServices) => {
        dispatch(actions.setUserServices(userServices));
    };

    const setSearchServiceText = (searchText) => {
        dispatch(actions.setSearchServiceText(searchText));
    };

    const setServiceSelected = (service) => {
        dispatch(actions.setServiceSelected(service));
    };

    const setNewService = (service) => {
        dispatch(actions.setNewService(service));
    };

    /****************************/
    /*   SERVICE - DEPARTMENT   */
    /****************************/

    const setDepartments = (departments) => {
        dispatch(actions.setDepartments(departments));
    };

    const setDistricts = (districts) => {
        dispatch(actions.setDistricts(districts));
    };

    const setDepartmentId = (departmentId) => {
        dispatch(actions.setDepartmentId(departmentId));
    };

    const setDepartmentName = (departmentName) => {
        dispatch(actions.setDepartmentName(departmentName));
    };

    const setDepartmentClassName = (className) => {
        dispatch(actions.setDepartmentClassName(className));
    };

    /****************************/
    /*    SERVICE - DISTRICT    */
    /****************************/

    const setDistrictId = (districtId) => {
        dispatch(actions.setDistrictId(districtId));
    };

    const setDistrictName = (districtName) => {
        dispatch(actions.setDistrictName(districtName));
    };
    /****************************/
    /*     SERVICE - IMAGES     */
    /****************************/

    const setServiceImages = (serviceImages) => {
        dispatch(actions.setServiceImages(serviceImages));
    };

    /****************/
    /*   CATEGORY   */
    /****************/

    const setCategoryId = (categoryId) => {
        dispatch(actions.setCategoryId(categoryId));
    };

    const setSubcategoryId = (subcategoryId) => {
        dispatch(actions.setSubcategoryId(subcategoryId));
    };

    const setSubCategories = (subCategories) => {
        dispatch(actions.setSubCategories(subCategories));
    };

    /****************/
    /*   COMPANY    */
    /****************/

    const setCompany = (company) => {
        dispatch(actions.setCompany(company));
    };

    /****************/
    /*   LOADING    */
    /****************/

    const setModalLoading = (modalLoading) => {
        dispatch(actions.setModalLoading(modalLoading));
    };

    /**********************/
    /*   REGISTER CODE    */
    /**********************/

    const setModalRegisterCodeOpen = (value) => {
        dispatch(actions.setModalRegisterCodeOpen(value));
    };

    /**********************/
    /*   RECOVER PASSWORD CODE    */
    /**********************/

    const setModalRecoverPasswordOpen = (value) => {
        dispatch(actions.setModalRecoverPasswordOpen(value));
    };

    /**********************/
    /*   OKMODAL    */
    /**********************/

    const setModalOkOpen = (value) => {
        dispatch(actions.setModalOkOpen(value));
    };

    const setMode = (value) => {
        dispatch(actions.setMode(value));
    };

    const setCollapsed = (value) => {
        dispatch(actions.setCollapsed(value));
    };

    const setDetailServiceOpened = (value) => {
        dispatch(actions.setDetailServiceOpened(value));
    };

    const setDetailServiceExtended = (value) => {
        dispatch(actions.setDetailServiceExtended(value));
    };

    const setShareOpened = (value) => {
        dispatch(actions.setShareOpened(value));
    };

    const handleServiceDetailVisibility = (service = null) => {
        if (appState.serviceSelected !== service) {
            setServiceSelected(service);
            setDetailServiceOpened(service !== null ? true : false);
            if (service !== null) {
                appState.mode === "map"
                    ? window.scroll(0, document.body.scrollHeight)
                    : setDetailServiceExtended(true);
            }
        }
    };

    const setPanelSideBarCollapsed = (value) => {
        dispatch(actions.setPanelSideBarCollapsed(value));
    };

    const handlePanelSideBarCollapsed = () => {
        let collapse = !appState.isPanelSideBarCollapsed;
        setPanelSideBarCollapsed(collapse);
    };

    const setConfirmModalOpen = (value) => {
        dispatch(actions.setConfirmModalOpen(value));
    };

    //Publish service

    const setPublishServiceModalOpen = (value) => {
        dispatch(actions.setPublishServiceModalOpen(value));
    };

    const setPublishServiceType = (value) => {
        dispatch(actions.setPublishServiceType(value));
    };

    const setPublishServiceStep = (value) => {
        dispatch(actions.setPublishServiceStep(value));

    };

    const setDistrictsAll = (districtsAll) => {
        dispatch(actions.setDistrictsAll(districtsAll));
    };

    const setModalRegisterComent = (value) => {
        dispatch(actions.setModalRegisterComent(value));
    };

    const setModalOkResena = (value) => {
        dispatch(actions.setModalOkResena(value));
    };

    const setResena = (value) => {
        dispatch(actions.setResena(value));
    };

    const setUsersAll = (value) => {
        dispatch(actions.setUsersAll(value));
    };

    const setModalInicioSesion = (value) => {
        dispatch(actions.setModalInicioSesion(value));
    };
    const setImageReseAll = (value) => {
        dispatch(actions.setImageReseAll(value));
    }
    const setNewServiceResena = (value) => {
        dispatch(actions.setNewServiceResena(value));
    }

    const setServiceSelectedAux = (value) => {
        dispatch(actions.setServiceSelectedAux(value));
    }

    const setModalListResena = (value) => {
        dispatch(actions.setModalListResena(value));
    }

    const setServiceList = (value) => {
        dispatch(actions.setServiceList(value));
    }
    
    const setIsImageAvaible = (value) => {
        dispatch(actions.setIsImageAvaible(value));
    } 

    const setServicesAll = (value) => {
        dispatch(actions.setServicesAll(value));
    } 

    const setImagenesAudio = (value) => {
        dispatch(actions.setImagenesAudio(value));
    } 

    const setAudioConfig = (value) => {
        dispatch(actions.setAudioConfig(value));
    } 

    const setIsActivoService = (value) => {
        dispatch(actions.setIsActivoService(value));
    } 

    const setNewServiceCopy = (value) => {
        dispatch(actions.setNewServiceCopy(value));
    } 

    const setImagenesPreloader = (value) => {
        dispatch(actions.setImagenesPreloader(value));
    } 

    const setActiveLogged = (value) => {
        dispatch(actions.setActiveLogged(value));
    } 

    const setActiveGeolocalizacion = (value) => {
        dispatch(actions.setActiveGeolocalizacion(value));
    } 
    const setActiveServicesAll = (value) => {
        dispatch(actions.setActiveServicesAll(value));
    } 

    const [appState, dispatch] = useReducer(AppReducer, INITIAL_STATE);

    return (
        <AppContext.Provider
            value={{
                appState,
                handlePanelSideBarCollapsed,
                handleServiceDetailVisibility,
                setCategoryId,
                setCollapsed,
                setCompany,
                setConfirmModalOpen,
                setCurrentLatLng,
                setDepartmentClassName,
                setDepartmentId,
                setDepartmentName,
                setDepartments,
                setDistricts,
                setDetailServiceExtended,
                setDetailServiceOpened,
                setDistrictId,
                setDistrictName,
                setLoggedUser,
                setModifiedUser,
                setModalLoading,
                setModalRegisterCodeOpen,
                setModalRecoverPasswordOpen,
                setModalOkOpen,
                setMode,
                setNewService,
                setPanelSideBarCollapsed,
                setPublishServiceModalOpen,
                setPublishServiceStep,
                setPublishServiceType,
                setSearchServiceText,
                setServiceImages,
                setServices,
                setUserServices,
                setServiceSelected,
                setShareOpened,
                setSubcategoryId,
                setSubCategories,
                signIn,
                signOut,
                setDistrictsAll,
                setModalRegisterComent,
                setModalOkResena,
                setResena,
                setUsersAll,
                setModalInicioSesion,
                setImageReseAll,
                setNewServiceResena,
                setServiceSelectedAux,
                setModalListResena,
                setServiceList,
                setIsImageAvaible,
                setServicesAll,
                setImagenesAudio,
                setAudioConfig,
                setIsActivoService,
                setNewServiceCopy,
                setImagenesPreloader,
                setActiveLogged,
                setActiveGeolocalizacion,
                setActiveServicesAll
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
