import { useRef, useState, useEffect } from "react";
import { api } from "../api/apiConfig";
import { useAppState } from "./useAppState";
import { useUser } from "./useUser";


export const useResena = () => {

    const { setResena, newService } = useAppState();

    const getResenaByService = (serviceId, callback = null) => {
        if (serviceId !== undefined) {
            api()
                .get("/Resenas/ObtenerPorIdServicio/" + serviceId)
                .then((resp) => {
                    const resenaData = resp.data;
                    if (callback) {
                        callback(resenaData);
                    }
                    setResena(resenaData);
                })
                .catch((error) => console.error(error));
        }
    }

    return {
        getResenaByService,

    };

}
